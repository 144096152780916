<template>
    <div class="py-4 container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="pb-0 card-header">
                        <div class="d-lg-flex">
                            <div>
                                <h5 class="mb-0">Add User - {{ user?.name }}</h5>
                            </div>
                        </div>
                    </div>
                    <div class="px-0 pb-0 card-body">
                        <div class="p-5 pt-0">
                            <div class="row">
                                <div class="col-6">
                                    <Input v-model="user.name" type="text" label="Name"></Input>
                                </div>
                                <div class="col-6">
                                    <Input v-model="user.email" type="email" label="Email"></Input>
                                </div>
                                <div class="col-6">
                                    <Choice v-model="user.role" :options="roles" label="Role" choice-id="user_roles"
                                        :searchable="false">
                                    </Choice>
                                </div>
                                <div class="col-6">
                                    <Choice v-model="user.department_id" :options="data.departments" label="Department"
                                        choice-id="user_departments">
                                    </Choice>
                                </div>
                                <div class="col-12">
                                    <Choice v-model:tags="user.job_functions" :options="data.job_functions"
                                        v-model:selected-tags="user.jobFunctions" label="User Job Functions" mode="tags"
                                        choice-id="user_job_functions">
                                    </Choice>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-footer">
                        <hr>
                        <div class="align-self-end">
                            <button class="btn btn-success btn-lg me-3" @click.prevent="submit">Submit</button>
                            <button class="btn btn-secondary btn-lg" @click.prevent="$alert.back">Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Input from '@/components/Input.vue';
import Choice from '@/components/Choice.vue';

export default {
    name: "AddUser",
    components: {
        Input,
        Choice
    },
    data() {
        return {
            user: {},
            data: {},
            roles: [{ id: 'admin', name: 'Admin User' }, { id: 'user', name: 'Regular User' }]
        }
    },
    mounted() {
        this.$http.get('api/users/data')
            .then((res) => {
                console.log(res.data)
                this.data = res.data
            })
    },
    methods: {
        submit() {
            console.log(this.user, 'userJson')
            this.$http.post('api/users', this.user)
                .then((res) => {
                    this.$alert.showSwal({
                        type: "success",
                        message: res.data.message,
                        width: 350,
                    });
                    this.$router.push({ name: 'Users' })
                }).catch((res) => {
                    if (res.data) {
                        this.$alert.showSwal({
                            type: "error",
                            message: res.data?.message,
                            width: 350,
                        });
                    }
                })
        },
    }
}
</script>