<template>
    <div class="py-4 container-fluid vh-screen-82">
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="pb-0 card-header">
                        <div class="row">
                            <div class="col-8"></div>
                            <div class="col-4">
                                <div class="input-group mb-4">
                                    <span class="input-group-text text-body"><i class="fas fa-search"
                                            aria-hidden="true"></i>
                                    </span><input v-model="query" type="text" class="form-control"
                                        placeholder="Type here...">
                                </div>
                            </div>
                        </div>
                        <div class="d-lg-flex">
                            <div>
                                <h5 class="mb-0">All Departments</h5>
                            </div>
                            <div class="my-auto mt-4 ms-auto mt-lg-0">
                                <div class="my-auto ms-auto">
                                    <button class="mb-0 btn btn-success btn-md"
                                        @click="$router.push({ name: 'Add Department' })">+&nbsp; New
                                        Department</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="px-0 pb-0 card-body">
                        <div class="table-responsive">
                            <table id="departments-list" class="table table-flush table-hover">
                                <thead class="thead-light">
                                    <tr>
                                        <th class="text-center">Name</th>
                                        <th class="text-center">Description</th>
                                        <th class="text-center">Status</th>
                                        <!-- <th class="text-center">Action</th> -->
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="department in filteredDepartments" :key="department.id" class="py-3">
                                        <td class="text-center">
                                            <h6 class="my-auto ms-3">{{ department.name }}</h6>
                                        </td>
                                        <td class="text-sm text-center">{{ department.description }}</td>
                                        <td class="text-center">
                                            <span class="badge badge-sm"
                                                :class="{ 'badge-danger': department.status == 0, 'badge-success': department.status == 1 }">{{
                                                    department.status == 1 ? 'Active' : 'In-Active' }}</span>
                                        </td>
                                        <!-- <td class="text-sm text-center">
                                            <a :id="'view_' + department.id" data-bs-toggle="tooltip"
                                                data-bs-original-title="Preview Department">
                                                <i class="fas fa-eye text-secondary"
                                                    @click="$router.push({ name: 'View Department', params: { id: department.id } })"></i>
                                            </a>
                                            <a v-if="user.role === 'admin'" :id="'edit_' + department.id" class="mx-3"
                                                data-bs-toggle="tooltip" data-bs-original-title="Edit Department"
                                                @click="$router.push({ name: 'Edit Department', params: { id: department.id } })">
                                                <i class="fas fa-user-edit text-secondary"></i>
                                            </a>
                                            <a v-if="user.role === 'admin'" :id="'delete_' + department.id"
                                                data-bs-toggle="tooltip" data-bs-original-title="Delete Department"
                                                @click=deleteDepartment(department.id)>
                                                <i class="fas fa-trash text-secondary"></i>
                                            </a>
                                        </td> -->
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "DepartmentMaster",
    data() {
        const user = JSON.parse(localStorage.getItem('user'));

        return { departments: [], query: '', user }
    },
    computed: {
        filteredDepartments() {
            return this.departments.filter((ele) => {
                return ele.name.toLowerCase().includes(this.query.toLowerCase());
            });
        }
    },
    mounted() {
        this.$http.get('api/departments').then((res) => {
            this.departments = res.data
        })
    },
    methods: {
    },
};
</script>