<template>
    <div class="py-4 container-fluid vh-screen">
        <div class="row">
            <div class="col-lg-7 position-relative z-index-2">
                <div class="mb-4 card card-plain">
                    <div class="p-3 card-body">
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="d-flex flex-column h-100">
                                    <h2 class="mb-0 font-weight-bolder">Dashboard</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <!-- globe -->
                <globe width="700" height="600" />
            </div>
        </div>
    </div>
</template>
<script>
import Globe from "../components/Globe.vue";

export default {
    name: "Dashboard",
    components: {
        Globe,
    },
    beforeMount() {
        const user = JSON.parse(localStorage.getItem('user'));
        if (user.role === 'super-admin')
            this.$router.push({ name: 'Super Dashboard' })
    }
};
</script>
