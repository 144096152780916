<template>
    <h1>Settings</h1>
    <button @click.prevent="getUserData">CLICK ME</button>
    {{ user?.name }}
</template>

<script>
export default {
    name: "Settings",
    data() {
        return { user: {} }
    },
    mounted() {
        console.log(window.location.domain)
    },
    methods: {
        async getUserData() {
            await this.$http.get("api/user")
                .then(
                    (res) => {
                        console.log(res.data)
                        this.user = res.data
                    }
                );
        }
    }
}
</script>