<template>
    <div class="py-4 container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="pb-0 card-header">
                        <div class="d-lg-flex">
                            <div>
                                <h5 class="mb-0">Add Policy Book - {{ policyBook?.name }}</h5>
                            </div>
                        </div>
                    </div>
                    <div class="px-0 pb-0 card-body">
                        <div class="px-5 py-0">
                            <div class="row">
                                <div class="col-12">
                                    <Input v-model="policyBook.name" type="text" label="Policy Book Name"></Input>
                                </div>
                                <div class="col-6">
                                    <Input v-model="policyBook.effective_from_date" type="date"
                                        label="Effective From"></Input>
                                </div>
                                <div class="col-6">
                                    <Choice v-model="policyBook.status" :options="statuses" label="Status"
                                        :searchable="false" choice-id="policy_categories">
                                    </Choice>
                                </div>
                                <div class="col-6">
                                    <Choice v-model="policyBook.category_id" :options="data.categories" label="Category"
                                        choice-id="policyBook_categories">
                                    </Choice>
                                </div>
                                <div class="col-6">
                                    <Input v-model="policyBook.description" type="text" label="Description"></Input>
                                </div>
                                <div class="col-6">
                                    <div class="form-check form-switch ps-0 ms-auto mt-3 mb-0">
                                        <input id="general" class="form-check-input ps-0 ms-auto" type="checkbox"
                                            v-model="policyBook.general">
                                        <label class="form-check-label mb-0 text-body ms-3 text-truncate w-80"
                                            for="general">For all users? (General)</label>
                                    </div>
                                </div>
                                <div class="col-12" v-if="!policyBook.general">
                                    <Choice v-model:selected-tags="policyBook.jobFunctions" :options="data.job_functions"
                                        label="Applicable Job Functions" mode="tags" choice-id="policy_job_functions"
                                        noTopMargin>
                                    </Choice>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-header pb-0 mb-3 mt-2">
                        <div class="d-lg-flex">
                            <div>
                                <strong>Policies</strong> (<small>double click policy to delete</small>)
                            </div>
                            <div class="my-auto mt-4 ms-auto mt-lg-0">
                                <div class="my-auto ms-auto">
                                    <button class="mb-0 btn btn-info btn-md" @click="addPolicy">+&nbsp; New
                                        Policy</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="px-0 pb-0 pt-0 card-body">
                        <div class="p-5 pt-0">
                            <div class="row">
                                <div class="col-3">
                                    <div class="nav-pills bg-transparent hide-scroll border p-1"
                                        style="height: 300px; overflow-y: scroll; overflow-x: hidden; border-radius: 12px; border-color: black;">
                                        <div class="row" v-for="(policy, index) in policyBook.policies">
                                            <div class="col-12">
                                                <button class="nav-link policy-btn mb-2 w-100" @click="togglePolicy(index)"
                                                    @dblclick="deletePolicy(index)" :class="{
                                                        'active bg-warning text-white border': index === activePolicy,
                                                        'bg-transparent text-warning': index !== activePolicy
                                                    }">{{ policy.name || 'New Policy' }}</button>
                                                <!-- <button class="btn mb-2 w-100" @click="togglePolicy(index)"
                                                    @dblclick="deletePolicy(index)" :class="{
                                                        'btn-warning': index === activePolicy,
                                                        'btn-outline-warning': index !== activePolicy
                                                    }">{{ policy.name || 'New Policy' }}</button> -->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-9 border p-1" style="border-radius: 12px;">
                                    <div class="tab-content" v-for="(policy, index) in policyBook.policies"
                                        v-show="activePolicy === index">
                                        <div class="row">
                                            <div class="col-12">
                                                <Input v-model="policy.name" type="text" placeholder="Policy Name"
                                                    no-top-margin no-label></Input>
                                            </div>
                                            <div class="col-12">
                                                <TextEditor v-model="policy.content" mode="input" label="Content"
                                                    :quill-id="'quill_policy_' + index" min-height="130">
                                                </TextEditor>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-footer">
                        <hr>
                        <div class="align-self-end">
                            <button class="btn btn-success btn-lg me-3" @click.prevent="submit">Submit</button>
                            <button class="btn btn-secondary btn-lg" @click.prevent="$alert.back">Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import TextEditor from '@/components/TextEditor.vue';
import Input from '@/components/Input.vue';
import Choice from '@/components/Choice.vue';

export default {
    name: "AddPolicy",
    components: {
        TextEditor,
        Input,
        Choice
    },
    data() {
        return {
            policyBook: {
                policies: [],
                jobFunctions: []
            },
            data: {},
            statuses: [{ id: 'published', name: 'Published' }, { id: 'draft', name: 'Draft' }, { id: 'unpublished', name: 'Unpublished' }],
            activePolicy: 0
        }
    },
    mounted() {
        this.$http.get('api/policies/data')
            .then((res) => {
                console.log(res.data);
                this.data = res.data
            })
    },
    methods: {
        submit() {
            console.log(this.policyBook)
            this.$http.post('api/policies', this.policyBook)
                .then((res) => {
                    this.$alert.showSwal({
                        type: "success",
                        message: res.data.message,
                        width: 350,
                    });
                    this.$router.push({ name: 'Policies Master' })
                })
                .catch((res) => {
                    if (res.data) {
                        this.$alert.showSwal({
                            type: "error",
                            message: res.data?.message,
                            width: 350,
                        });
                    }
                })
        },
        deletePolicy(index) {
            this.policyBook.policies.splice(index, 1);
        },
        togglePolicy(index) {
            this.activePolicy = index
        },
        addPolicy() {
            this.policyBook.policies.push({})
        }
    }
}
</script>

<style scoped>
.policy-btn:hover {
    background-color: #fbcf33 !important;
    color: white !important;
}

/* .policy-btn:hover {
    background-color: #17c1e8 !important;
    color: white !important;
} */
</style>

