<template>
    <div class="py-4 container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="pb-0 card-header">
                        <div class="d-lg-flex">
                            <div>
                                <h5 class="mb-0">Add Department - {{ department?.name }}</h5>
                            </div>
                        </div>
                    </div>
                    <div class="px-0 pb-0 card-body">
                        <div class="p-5 pt-0">
                            <div class="row">
                                <div class="col-6">
                                    <Input v-model="department.name" type="text" label="Department Name"></Input>
                                </div>
                                <div class="col-6">
                                    <Input v-model="department.description" type="text" label="Description"></Input>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-footer">
                        <hr>
                        <div class="align-self-end">
                            <button class="btn btn-success btn-lg me-3" @click.prevent="submit">Submit</button>
                            <button class="btn btn-secondary btn-lg" @click.prevent="$alert.back">Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Input from '@/components/Input.vue';

export default {
    name: "AddDepartment",
    components: {
        Input
    },
    data() {
        return { department: {} }
    },
    methods: {
        submit() {
            this.$http.post('api/departments', this.department)
                .then((res) => {
                    this.$alert.showSwal({
                        type: "success",
                        message: res.data.message,
                        width: 350,
                    });
                    this.$router.push({ name: 'Departments' })
                }).catch((res) => {
                    if (res.data) {
                        this.$alert.showSwal({
                            type: "error",
                            message: res.data?.message,
                            width: 350,
                        });
                    }
                })
        },
    }
}
</script>