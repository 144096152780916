import axios from "axios";
import router from "@/router";
import showSwal from "/src/mixins/showSwal.js";

const development = "production";
const tenant = "default";

// Base URL
if (development === "local") {
  axios.defaults.baseURL =
    tenant === "default"
      ? "http://testing.localhost:8000/"
      : `http://${tenant}.localhost:8000`; // Local Testing Laravel
}

if (development === "server") {
  axios.defaults.baseURL = "https://testing.backend.macroify.com/"; // Deployment Multi Tenancy testing from local
}

if (development === "production") {
  const { protocol } = window.location;
  const hostnameParts = window.location.hostname.split(".");
  const subdomain = hostnameParts[0];
  const restOfHostname = hostnameParts.slice(1).join(".");

  // if (
  //   `${protocol}//${subdomain}.backend.${restOfHostname}` ===
  //   "https://macroify.backend.com"
  // ) {
  //   axios.defaults.baseURL = "https://admin.backend.macroify.com"; // Deployment Multi Tenancy Live.
  // } else {
  //   axios.defaults.baseURL = `${protocol}//${subdomain}.backend.${restOfHostname}`; // Deployment Multi Tenancy Live.
  // }
  if (
    `${protocol}//${window.location.hostname}` ===
    "https://macroify.asalam.online"
  ) {
    axios.defaults.baseURL = "https://macroifytenant.asalam.online"; // Deployment Multi Tenancy Live.
  } else {
    axios.defaults.baseURL = `https://macroifymain.asalam.online`; // Deployment Multi Tenancy Live.
  }
}

function extractAndFormatErrorMessages(errorData) {
  const errorMessages = [];

  for (const key in errorData) {
    if (errorData.hasOwnProperty(key)) {
      errorData[key].forEach((message) => {
        // Remove the word "id" from the error message
        const cleanedMessage = message
          .replace(/ id /g, " ")
          .replace(/^The\s+/i, "");
        const CapitalizedMessage =
          cleanedMessage.charAt(0).toUpperCase() + cleanedMessage.slice(1);
        errorMessages.push(CapitalizedMessage);
      });
    }
  }

  // Join the error messages with line breaks
  return errorMessages.join("|");
}

// Error Handling
axios.interceptors.response.use(
  (response) => response, // Do nothing on successful responses
  (error) => {
    if (error.response && error.response.status === 422) {
      const errorsMessage = extractAndFormatErrorMessages(
        error.response.data.errors
      );
      showSwal.methods.validationError(errorsMessage);
      // console.log(errorsMessage, 'INTERCEPTOR');
    }
    if (error.response && error.response.status === 401) {
      localStorage.removeItem("user");
      localStorage.removeItem("token");
      router.push({ name: "Login" });
      showSwal.methods.validationError(
        "Something went wrong, please log in again."
      );
    }
    // console.log(error, "Global Interceptor");
    return Promise.reject(error);
  }
);

export default axios;
