<template>
  <div id="sidenav-collapse-main" class="w-auto h-auto collapse navbar-collapse max-height-vh-100 h-100"
    style="overflow-x: hidden !important;">
    <ul class="navbar-nav">
      <li class="nav-item">
        <sidenav-collapse nav-text="Dashboard" collapse-ref="Dashboard" :collapse="false"
          :class="getRoute() === 'dashboard' || (getRoute() === 'admin' && getRoute(2) === undefined) ? 'active' : ''"
          @click.prevent="$router.push({ name: user.role !== 'super-admin' ? 'Dashboard' : 'Super Dashboard' })">
          <template #icon>
            <Shop />
          </template>
        </sidenav-collapse>
      </li>
      <li class="mt-3 nav-item">
        <h6 class="text-xs ps-4 text-uppercase font-weight-bolder opacity-6" :class="isRTL ? 'me-4' : 'ms-2'">
          PAGES
        </h6>
      </li>
      <li class="nav-item" v-if="user.role !== 'super-admin'">
        <sidenav-collapse collapse-ref="StandardOfProcedures" nav-text="SOP"
          :class="getRoute() === 'sop' ? 'active' : ''">
          <template #icon>
            <Office />
          </template>
          <template #list>
            <ul class="nav ms-4 ps-3">
              <!-- nav links -->
              <sidenav-item :to="{ name: 'SOP Master' }" miniIcon="S"
                :text="user.role === 'admin' ? 'All SOPs' : 'SOPs'" />
              <sidenav-item v-if="user.role === 'admin'" :to="{ name: 'Add SOP' }" miniIcon="A" text="Add SOP" />
            </ul>
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item" v-if="user.role !== 'super-admin'">
        <sidenav-collapse collapse-ref="Policies" nav-text="Policies" :class="getRoute() === 'policy' ? 'active' : ''">
          <template #icon>
            <Office />
          </template>
          <template #list>
            <ul class="nav ms-4 ps-3">
              <!-- nav links -->
              <sidenav-item v-if="user.role === 'admin'" :to="{ name: 'Policies Master' }" mini-icon="P"
                :text="user.role === 'admin' ? 'Policy Master' : 'Policies'" />
              <sidenav-item v-if="user.role === 'admin'" :to="{ name: 'Add Policy' }" mini-icon="A" text="Add Policy" />
              <sidenav-item :to="{ name: 'Assigned Policies' }" mini-icon="P" text="Assigned Policies" />
            </ul>
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item" v-if="user.role !== 'super-admin'">
        <sidenav-collapse collapse-ref="KnowledgeBase" nav-text="Knowledge Base"
          :class="getRoute() === 'knowledge' ? 'active' : ''">
          <template #icon>
            <Basket />
          </template>
          <template #list>
            <ul class="nav ms-4 ps-3">
              <!-- nav links -->
              <template v-if="user.role === 'admin'">
                <sidenav-collapse-item refer="faq" mini-icon="F" text="FAQs">
                  <template #nav-child-item>
                    <sidenav-item :to="{ name: 'FAQ Master' }" mini-icon="F" v-if="user.role === 'admin'"
                      :text="'All FAQs'" />
                    <sidenav-item v-if="user.role === 'admin'" :to="{ name: 'Add FAQ' }" mini-icon="A" text="Add FAQ" />
                    <sidenav-item :to="{ name: 'User FAQ' }" mini-icon="F" :text="'FAQs'" />
                  </template>
                </sidenav-collapse-item>
                <sidenav-collapse-item refer="wiki" mini-icon="W" text="Wiki">
                  <template #nav-child-item>
                    <sidenav-item v-if="user.role === 'admin'" :to="{ name: 'Wiki Master' }" mini-icon="W"
                      :text="user.role === 'admin' ? 'All Wikis' : 'Wiki'" />
                    <sidenav-item v-if="user.role === 'admin'" :to="{ name: 'Add Wiki' }" mini-icon="A" text="Add Wiki" />
                    <sidenav-item :to="{ name: 'User Wiki' }" mini-icon="A" text="Wikis" />
                  </template>
                </sidenav-collapse-item>
              </template>
              <template v-else>
                <sidenav-item :to="{ name: 'User FAQ' }" mini-icon="F" text="FAQs" />
                <sidenav-item :to="{ name: 'User Wiki' }" mini-icon="A" text="Wikis" />
              </template>
            </ul>
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item" v-if="user.role === 'super-admin'">
        <sidenav-collapse collapse-ref="Tenants" nav-text="Tenants"
          :class="(getRoute() === 'admin' && getRoute(2) === 'tenants') ? 'active' : ''">
          <template #icon>
            <Office />
          </template>
          <template #list>
            <ul class="nav ms-4 ps-3">
              <!-- nav links -->
              <sidenav-item :to="{ name: 'Tenants' }" miniIcon="T" text="Tenants" />
              <sidenav-item v-if="user.role === 'admin'" :to="{ name: 'Add Tenant' }" miniIcon="A" text="Add Tenant" />
            </ul>
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item" v-if="user.role === 'admin' || user.role === 'super-admin'">
        <sidenav-collapse collapse-ref="Settings" nav-text="Settings"
          :class="getRoute() === 'settings' || (getRoute() === 'admin' && getRoute(2) === 'settings') ? 'active' : ''">
          <template #icon>
            <Settings />
          </template>
          <template #list>
            <ul class="nav ms-4 ps-3" v-if="user.role !== 'super-admin'">
              <!-- nav links -->
              <sidenav-item :to="{ name: 'Categories' }" mini-icon="C" text="Categories" />
              <sidenav-item :to="{ name: 'Departments' }" mini-icon="D" text="Departments" />
              <sidenav-item :to="{ name: 'Job Functions' }" mini-icon="J" text="Job Functions" />
              <sidenav-item :to="{ name: 'Users' }" mini-icon="U" text="Users" />
            </ul>
            <ul class="nav ms-4 ps-3" v-else>
              <!-- nav links -->
              <sidenav-item :to="{ name: 'Super Users' }" mini-icon="U" text="Users" />
            </ul>
          </template>
        </sidenav-collapse>
      </li>
    </ul>
  </div>
</template>
<script>
import SidenavItem from "./SidenavItem.vue";
import SidenavCollapse from "./SidenavCollapse.vue";
import SidenavCollapseItem from "./SidenavCollapseItem.vue";
import Shop from "../../components/Icon/Shop.vue";
import Office from "../../components/Icon/Office.vue";
import Settings from "../../components/Icon/Settings.vue";
import Basket from "../../components/Icon/Basket.vue";

import { mapState } from "vuex";
export default {
  name: "SidenavList",
  components: {
    SidenavItem,
    SidenavCollapse,
    SidenavCollapseItem,
    Shop,
    Basket,
    Office,
    Settings,
  },
  props: {
    cardBg: {
      type: String,
      default: "",
    },
  },
  computed: {
    ...mapState(["isRTL"]),
  },
  methods: {
    getRoute(index = 1) {
      const routeArr = this.$route.path.split("/");
      return routeArr[index];
    },
  },
  data() {
    var user = JSON.parse(localStorage.getItem('user'))

    if (user === null) {
      user = {
        role: 'user'
      }
    }
    return { user }
  }
};
</script>
