<!-- eslint-disable vue/no-v-html -->
<template>
    <!-- {{ this.quillDelta }} -->
    <div v-if="mode === 'input'" class="col-sm-12">
        <label :class="noTopMargin ? '' : 'mt-4'" v-if="!noLabel">{{ label }}</label>
        <p v-if="subLabel" class="text-xs form-text text-muted ms-1 d-inline">{{ subLabel }}</p>
        <div :id="quillId" class="h-50 w-100" :style="minHeight ? `min-height: ${minHeight}px;` : ''">
        </div>
    </div>
    <div v-if="mode === 'view'" class="col-sm-12">
        <div v-if="quillDelta" v-html="getHTML(quillDelta)" :style="minHeight ? `min-height: ${minHeight}px;` : ''"></div>
    </div>
    <div v-if="mode === 'edit'" class="col-sm-12">
        <!-- <div class="mt-3 row"> -->
        <!-- <div class="col-6">
                <div v-if="quill" v-html="getHTML(quillDelta)"></div>
            </div> -->
        <div class="col-12">
            <label :class="noTopMargin ? '' : 'mt-4'" v-if="!noLabel">{{ label }}</label>
            <p v-if="subLabel" class="text-xs form-text text-muted ms-1 d-inline">{{ subLabel }}</p>
            <div :id="quillId" class="h-50 w-100" :style="minHeight ? `min-height: ${minHeight}px;` : ''">
                <div v-html="getHTML(modelValue, true)"></div>
            </div>
        </div>
        <!-- </div> -->
    </div>
</template>

<script>
import Quill from "quill";
export default {
    name: "TextEditor",
    props: {
        // eslint-disable-next-line vue/require-default-prop
        modelValue: String,
        quillId: {
            type: String,
            default: 'quill'
        },
        label: {
            type: String,
            default: 'Text Editor'
        },
        subLabel: {
            type: String,
            default: null
        },
        mode: {
            type: String,
            default: 'input'
        },
        noTopMargin: {
            type: Boolean,
            default: false
        },
        noLabel: {
            type: Boolean,
            default: false
        },
        minHeight: {
            type: String,
            default: null
        }
    },
    emits: ['update:modelValue'],
    data() {
        var quillDelta = this.modelValue ? this.modelValue : ''

        var toolbarOptions = [
            [{ 'font': [] }, { 'header': [6, 5, 4, 3, 2, 1, false] }],

            ['bold', 'italic', 'underline', 'strike', { 'align': [] }, { 'color': [] }, { 'background': [] }],        // toggled buttons

            [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],

            // ['link', 'image', 'video'],          // add's image support
            ['link', 'video'],          // add's image support

            [{ 'script': 'sub' }, { 'script': 'super' }, 'blockquote', 'code-block', { 'direction': 'rtl' }],      // superscript/subscript

            ['clean']                                         // remove formatting button
        ];

        return { quillDelta, toolbarOptions, quill: null }
    },
    watch: {
        // whenever question changes, this function will run
        modelValue: {
            immediate: true,
            handler(newValue) {
                if ((this.mode === 'view') && newValue !== undefined) {
                    this.quillDelta = JSON.parse(newValue);
                    console.log('ere')
                }
            }
        }
    },
    mounted() {
        if (document.getElementById(this.quillId)) {
            this.quill = new Quill("#" + this.quillId, {
                theme: "snow", // Specify theme in configuration
                // disabled: this.mode === 'edit' ? true : false
                modules: {
                    toolbar: {
                        container: this.toolbarOptions,
                        // handlers: { image: this.quill_img_handler },
                    },
                },
                placeholder: 'Write to your heart\'s content!'
            });
            this.quill.on('text-change', this.change);
        }
        this.getHTML(this.quillDelta);
    },
    methods: {
        getHTML(inputDelta, parse = false) {
            try {
                let parsedDelta
                if (parse) {
                    parsedDelta = JSON.parse(inputDelta)
                } else {
                    parsedDelta = inputDelta
                }
                var tempCont = document.createElement("div");
                (new Quill(tempCont)).setContents(parsedDelta);
                var qlEditorElement = tempCont.querySelector(".ql-editor");
                return qlEditorElement ? qlEditorElement.innerHTML : "";
            } catch {
                return ""
            }
        },

        change() {
            this.quillDelta = this.quill.getContents()
            this.$emit('update:modelValue', JSON.stringify(this.quillDelta))
        },

        quill_img_handler() {
            const container = document.getElementById(this.quillId);
            let fileInput = container.querySelector('input.ql-image[type=file]');

            if (fileInput == null) {
                fileInput = document.createElement('input');
                fileInput.setAttribute('type', 'file');
                fileInput.setAttribute('accept', 'image/png, image/gif, image/jpeg, image/bmp, image/x-icon');
                fileInput.classList.add('ql-image');
                fileInput.addEventListener('change', () => {
                    const files = fileInput.files;
                    const range = this.quill.getSelection(true);

                    if (!files || !files.length) {
                        console.log('No files selected');
                        return;
                    }

                    const formData = new FormData();
                    formData.append('file', files[0]);

                    console.log(formData, 'FD')

                    this.quill.enable(false);

                    this.$http
                        .post('/api/image', formData)
                        .then(response => {
                            this.quill.enable(true);
                            this.quill.editor.insertEmbed(range.index, 'image', response.data.url_path);
                            this.quill.setSelection(range.index + 1, Quill.sources.SILENT);
                            fileInput.value = '';
                        })
                        .catch(error => {
                            console.log('quill image upload failed');
                            console.log(error);
                            this.quill.enable(true);
                        });
                });
                container.appendChild(fileInput);
            }
            fileInput.click();
        }
    }
};
</script>

<style>
span.ql-align.ql-picker.ql-icon-picker {
    margin-top: -3px;
}

span.ql-color.ql-picker.ql-color-picker {
    margin-top: -2px;
}

span.ql-background.ql-picker.ql-color-picker {
    margin-top: -3px;
}
</style>