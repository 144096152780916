<template>
    <div class="py-4 container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="pb-0 card-header">
                        <div class="d-lg-flex">
                            <div>
                                <h5 class="mb-0">View Standard Operational Procedure (SOP) - {{ sop?.name }}</h5>
                            </div>
                        </div>
                    </div>
                    <div class="px-0 pb-0 card-body">
                        <div class="p-5">
                            <TextEditor v-model="sop.content" mode="view" label=""></TextEditor>
                        </div>
                    </div>
                    <div class="card-footer">
                        <div class="row">
                            <div class="col-8"></div>
                            <div class="col-4">
                                <button class="btn btn-secondary btn-md w-100"
                                    @click.prevent="$router.go(-1);">Back</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import TextEditor from '@/components/TextEditor.vue';


export default {
    name: "ViewSOP",
    components: {
        TextEditor
    },
    data() {
        return { sop: {} }
    },

    mounted() {
        let id = this.$router.currentRoute.value.params.id
        this.$http.get('api/sops/' + id)
            .then((res) => {
                this.sop = res.data
                // this.sop.content = JSON.parse(res.data.content)
            })
    },
}
</script>