<template>
    <div class="py-4 container-fluid vh-screen-82">
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="pb-0 card-header">
                        <div class="row">
                            <div class="col-8"></div>
                            <div class="col-4">
                                <div class="input-group mb-4">
                                    <span class="input-group-text text-body"><i class="fas fa-search"
                                            aria-hidden="true"></i>
                                    </span><input v-model="query" type="text" class="form-control"
                                        placeholder="Type here...">
                                </div>
                            </div>
                        </div>
                        <div class="d-lg-flex">
                            <div>
                                <h5 class="mb-0">All FAQ Records (Double Click Row to Show Answer)</h5>
                            </div>
                            <div class="my-auto mt-4 ms-auto mt-lg-0">
                                <div class="my-auto ms-auto">
                                    <button v-if="user.role === 'admin'" class="mb-0 btn btn-success btn-md"
                                        @click="$router.push({ name: 'Add FAQ' })">+&nbsp; New
                                        FAQ</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="px-0 pb-0 card-body">
                        <div class="table-responsive">
                            <table id="faqs-list" class="table table-flush table-hover">
                                <thead class="thead-light">
                                    <tr>
                                        <th class="text-center">Question</th>
                                        <th class="text-center">Category</th>
                                        <th class="text-center">Department</th>
                                        <th class="text-center">Status</th>
                                        <th class="text-center">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <template v-for="faq in filteredFAQs" :key="faq.id">
                                        <tr class="py-3" @dblclick="faq.showAnswer = faq.showAnswer ? false : true">
                                            <td class="text-center">
                                                <h6 class="my-auto ms-3">{{ faq.question }}</h6>
                                            </td>
                                            <td class="text-sm text-center">{{ faq.category.name }}</td>
                                            <td class="text-sm text-center">{{ faq.department.name }}</td>
                                            <td class="text-center">
                                                <span class="badge badge-sm"
                                                    :class="{ 'badge-success': faq.status === 'published', 'badge-secondary': faq.status == 'unpublished', 'badge-info': faq.status === 'draft' }">{{
                                                        faq.status.toUpperCase() }}</span>
                                            </td>
                                            <td class="text-sm text-center">
                                                <a v-if="user.role === 'admin'" :id="'edit_' + faq.id" class="mx-3"
                                                    data-bs-toggle="tooltip" data-bs-original-title="Edit product"
                                                    @click="$router.push({ name: 'Edit FAQ', params: { id: faq.id } })">
                                                    <i class="fas fa-user-edit text-secondary"></i>
                                                </a>
                                                <a v-if="user.role === 'admin'" :id="'delete_' + faq.id"
                                                    data-bs-toggle="tooltip" data-bs-original-title="Delete product"
                                                    @click=deleteFAQ(faq.id)>
                                                    <i class="fas fa-trash text-danger"></i>
                                                </a>
                                            </td>
                                        </tr>
                                        <tr v-if="faq.showAnswer && faq.answer">
                                            <td colspan="5" class="text-center">
                                                <TextEditor v-model="faq.answer" mode="view" label=""></TextEditor>
                                            </td>
                                        </tr>
                                    </template>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import TextEditor from '@/components/TextEditor.vue';

export default {
    name: "FAQMaster",
    data() {
        const user = JSON.parse(localStorage.getItem('user'));
        return { faqs: [], faqs2: [], query: '', user }
    },
    computed: {
        filteredFAQs() {
            return this.faqs.filter((ele) => {
                return ele.question.toLowerCase().includes(this.query.toLowerCase()) ||
                    ele.department.name.toLowerCase().includes(this.query.toLowerCase()) ||
                    ele.category.name.toLowerCase().includes(this.query.toLowerCase())
            });
        }
    },
    mounted() {
        this.$http.get('api/faqs').then((res) => {
            this.faqs = res.data
        })
    },
    components: {
        TextEditor
    },
    methods: {
        push(id) {
            console.log('here')
            this.$router.push({ name: 'View FAQ', params: { id: id } })
        },
        deleteFAQ(id) {
            this.$alert.tableDelete({
                path: `/api/faqs/${id}`
            }).then((res) => {
                if (res.isConfirmed) {
                    this.$http.get('api/faqs').then((res) => {
                        this.faqs = res.data
                    })
                }
            })
        }
    },
};
</script>