<!-- eslint-disable vue/no-mutating-props -->
<template>
    <div class="col-12">
        <label :class="{ 'mt-4': !noTopMargin }" v-if="!noLabel">{{ label }}</label>
        <input v-model="localModelValue" class="form-control" :type="type" :placeholder="placeholder"
            @change="$emit('update:modelValue', localModelValue)" />
        <small v-if="subLabel" class="text-xs form-text d-inline" style="color: #6c757d;">{{ subLabel }}</small>
    </div>
</template>

<script>
export default {
    name: "Input",
    props: {
        // eslint-disable-next-line vue/require-default-prop
        modelValue: String,
        type: {
            type: String,
            default: 'text'
        },
        label: {
            type: String,
            default: 'Label!'
        },
        placeholder: {
            type: String,
            default: ''
        },
        subLabel: {
            type: String,
            default: null
        },
        noTopMargin: {
            type: Boolean,
            default: false
        },
        noLabel: {
            type: Boolean,
            default: false
        }
    },
    emits: ['update:modelValue'],
    watch: {
        modelValue: {
            immediate: true,
            handler(newInput) {
                this.localModelValue = newInput;
            }
        }
    },
    data() {
        return { localModelValue: this.modelValue }
    }
}
</script>