<template>
    <div class="py-4 container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="pb-0 card-header">
                        <div class="d-lg-flex">
                            <div>
                                <h5 class="mb-0">Edit Standard Operational Procedure (SOP) - {{ sop?.name }}</h5>
                            </div>
                        </div>
                    </div>
                    <div class="px-0 pb-0 card-body">
                        <div class="p-5 pt-0">
                            <div class="row">
                                <div class="col-12">
                                    <Input v-model="sop.name" type="text" label="SOP Name"></Input>
                                </div>
                            </div>
                            <div v-if="show" class="row">
                                <div class="col-6">
                                    <Choice v-model="sop.category_id" :options="data.categories" label="Categories"
                                        choice-id="sop_categories">
                                    </Choice>
                                </div>
                                <div class="col-6">
                                    <Choice v-model="sop.department_id" :options="data.departments" label="Departments"
                                        choice-id="sop_departments">
                                    </Choice>
                                </div>
                                <div class="col-7">
                                    <div class="form-check form-switch ps-0 ms-auto mt-3 mb-0">
                                        <input id="general" class="form-check-input ps-0 ms-auto" type="checkbox"
                                            v-model="sop.general">
                                        <label class="form-check-label mb-0 text-body ms-3 text-truncate w-80"
                                            for="general">For all users? (General)</label>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <Choice :tags="sop.job_functions" v-model:selected-tags="sop.jobFunctions"
                                        :options="data.job_functions" label="Job Functions" mode="tags"
                                        choice-id="sop_job_functions">
                                    </Choice>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <TextEditor v-if="sop.content !== undefined" v-model="sop.content" mode="edit"
                                        label="Edit SOP Content"></TextEditor>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-footer">
                        <hr>
                        <div class="align-self-end">
                            <button class="btn btn-success btn-lg me-3" @click.prevent="submit">Submit</button>
                            <button class="btn btn-secondary btn-lg" @click.prevent="$alert.back">Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import TextEditor from '@/components/TextEditor.vue';
import Input from '@/components/Input.vue';
import Choice from '@/components/Choice.vue';

export default {
    name: "EditSOP",
    components: {
        TextEditor,
        Input,
        Choice
    },
    data() {
        return { sop: {}, data: {}, show: false }
    },
    mounted() {
        let id = this.$router.currentRoute.value.params.id
        this.$http.get('api/sops/' + id)
            .then((res) => {
                this.sop = res.data
                this.show = true
            })
        this.$http.get('api/sops/data')
            .then((res) => {
                this.data = res.data
            })
    },
    methods: {
        submit() {
            // console.log(this.sop)
            let id = this.$router.currentRoute.value.params.id
            this.$http.put('api/sops/' + id, this.sop)
                .then((res) => {
                    this.$alert.showSwal({
                        type: "success",
                        message: res.data.message,
                        width: 350,
                    });
                    this.$router.push({ name: 'SOP Master' })
                }).catch((res) => {
                    if (res.data) {
                        this.$alert.showSwal({
                            type: "error",
                            message: res.data?.message,
                            width: 350,
                        });
                    }
                })
        }
    }
}
</script>