<template>
    <!-- Navbar -->
    <nav class="top-0 navbar navbar-expand-lg position-absolute z-index-3"
        :class="isBlur ? isBlur : 'shadow-none my-2 navbar-transparent w-100'">
        <div class="container">
            <router-link class="navbar-brand font-weight-bolder ms-lg-0 ms-3"
                :class="darkMode ? 'text-black' : 'text-white'" to="/">Macrofiy</router-link>
            <button class="shadow-none navbar-toggler ms-2" type="button" data-bs-toggle="collapse"
                data-bs-target="#navigation" aria-controls="navigation" aria-expanded="false"
                aria-label="Toggle navigation">
                <span class="mt-2 navbar-toggler-icon">
                    <span class="navbar-toggler-bar bar1"></span>
                    <span class="navbar-toggler-bar bar2"></span>
                    <span class="navbar-toggler-bar bar3"></span>
                </span>
            </button>
            <div id="navigation" class="pt-3 pb-2 collapse navbar-collapse w-100 py-lg-0">
                <ul class="mx-auto navbar-nav navbar-nav-hover">
                    <li class="mx-2 nav-item dropdown dropdown-hover">
                        <a id="dropdownMenuPages" role="button"
                            class="cursor-pointer nav-link ps-2 d-flex justify-content-between align-items-center"
                            :class="[darkModes]" data-bs-toggle="dropdown" aria-expanded="false">
                            SOP
                            <img :src="downArrWhite" alt="down-arrow" class="arrow ms-1"
                                :class="darkMode ? 'd-none' : 'd-lg-block d-none'" />
                            <img :src="downArrBlack" alt="down-arrow" class="arrow ms-1 d-block"
                                :class="darkMode ? 'd-lg-block' : 'd-lg-none'" />
                        </a>
                        <div class="p-2 px-1 mt-0 dropdown-menu dropdown-menu-animation border-radius-xl mt-lg-3"
                            aria-labelledby="dropdownMenuPages">
                            <div class="row d-none d-lg-block">
                                <div class="px-4 py-2 col-12">
                                    <div class="row">
                                        <div class="position-relative">
                                            <router-link :to="{ name: 'SOP Master' }"
                                                class="dropdown-item border-radius-md">
                                                <span>{{ user.role === 'admin' ? 'All SOPs' : 'SOPs' }}</span>
                                            </router-link>
                                            <router-link v-if="user.role === 'admin'" :to="{ name: 'Add SOP' }"
                                                class="dropdown-item border-radius-md">
                                                <span>Add SOP</span>
                                            </router-link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- responsive -->
                            <div class="d-lg-none">
                                <router-link :to="{ name: 'SOP Master' }" class="dropdown-item border-radius-md">{{
                                    user.role === 'admin' ? 'All SOPs' : 'SOPs' }}</router-link>
                                <router-link v-if="user.role === 'admin'" :to="{ name: 'Add SOP' }"
                                    class="dropdown-item border-radius-md">Add SOP</router-link>
                            </div>
                        </div>
                    </li>
                    <li class="mx-2 nav-item dropdown dropdown-hover">
                        <a id="dropdownMenuPages" role="button"
                            class="cursor-pointer nav-link ps-2 d-flex justify-content-between align-items-center"
                            :class="[darkModes]" data-bs-toggle="dropdown" aria-expanded="false">
                            Policies
                            <img :src="downArrWhite" alt="down-arrow" class="arrow ms-1"
                                :class="darkMode ? 'd-none' : 'd-lg-block d-none'" />
                            <img :src="downArrBlack" alt="down-arrow" class="arrow ms-1 d-block"
                                :class="darkMode ? 'd-lg-block' : 'd-lg-none'" />
                        </a>
                        <div class="p-2 px-1 mt-0 dropdown-menu dropdown-menu-animation border-radius-xl mt-lg-3"
                            aria-labelledby="dropdownMenuPages">
                            <div class="row d-none d-lg-block">
                                <div class="px-4 py-2 col-12">
                                    <div class="row">
                                        <div class="position-relative">
                                            <router-link v-if="user.role === 'admin'" :to="{ name: 'Policies Master' }"
                                                class="dropdown-item border-radius-md">
                                                <span>All Policies</span>
                                            </router-link>
                                            <router-link v-if="user.role === 'admin'" :to="{ name: 'Add Policy' }"
                                                class="dropdown-item border-radius-md">
                                                <span>Add Policy</span>
                                            </router-link>
                                            <router-link :to="{ name: 'Assigned Policies' }"
                                                class="dropdown-item border-radius-md">
                                                <span>Assigned Policies</span>
                                            </router-link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- responsive -->
                            <div class="d-lg-none">
                                <router-link v-if="user.role === 'admin'" :to="{ name: 'Policies Master' }"
                                    class="dropdown-item border-radius-md">
                                    <span>All Policies</span>
                                </router-link>
                                <router-link v-if="user.role === 'admin'" :to="{ name: 'Add Policy' }"
                                    class="dropdown-item border-radius-md">
                                    <span>Add Policy</span>
                                </router-link>
                                <router-link :to="{ name: 'Assigned Policies' }" class="dropdown-item border-radius-md">
                                    <span>Assigned Policies</span>
                                </router-link>
                            </div>
                        </div>
                    </li>
                    <li class="mx-2 nav-item dropdown dropdown-hover">
                        <a id="dropdownMenuPages" role="button"
                            class="cursor-pointer nav-link ps-2 d-flex justify-content-between align-items-center"
                            :class="[darkModes]" data-bs-toggle="dropdown" aria-expanded="false">
                            FAQ
                            <img :src="downArrWhite" alt="down-arrow" class="arrow ms-1"
                                :class="darkMode ? 'd-none' : 'd-lg-block d-none'" />
                            <img :src="downArrBlack" alt="down-arrow" class="arrow ms-1 d-block"
                                :class="darkMode ? 'd-lg-block' : 'd-lg-none'" />
                        </a>
                        <div class="p-2 px-1 mt-0 dropdown-menu dropdown-menu-animation border-radius-xl mt-lg-3"
                            aria-labelledby="dropdownMenuPages">
                            <div class="row d-none d-lg-block">
                                <div class="px-4 py-2 col-12">
                                    <div class="row">
                                        <div class="position-relative">
                                            <router-link :to="{ name: 'FAQ Master' }" v-if="user.role === 'admin'"
                                                class="dropdown-item border-radius-md">
                                                <span>All FAQs</span>
                                            </router-link>
                                            <router-link v-if="user.role === 'admin'" :to="{ name: 'Add FAQ' }"
                                                class="dropdown-item border-radius-md">
                                                <span>Add FAQ</span>
                                            </router-link>
                                            <router-link :to="{ name: 'User FAQ' }" class="dropdown-item border-radius-md">
                                                <span>FAQs</span>
                                            </router-link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- responsive -->
                            <div class="d-lg-none">
                                <router-link :to="{ name: 'FAQ Master' }" v-if="user.role === 'admin'"
                                    class="dropdown-item border-radius-md">{{
                                        user.role === 'admin' ? 'All FAQs' : 'FAQs' }}</router-link>
                                <router-link v-if="user.role === 'admin'" :to="{ name: 'Add FAQ' }"
                                    class="dropdown-item border-radius-md">Add FAQ</router-link>
                                <router-link :to="{ name: 'User FAQ' }" class="dropdown-item border-radius-md">
                                    <span>FAQs</span>
                                </router-link>
                            </div>
                        </div>
                    </li>
                    <li class="mx-2 nav-item dropdown dropdown-hover">
                        <a id="dropdownMenuPages" role="button"
                            class="cursor-pointer nav-link ps-2 d-flex justify-content-between align-items-center"
                            :class="[darkModes]" data-bs-toggle="dropdown" aria-expanded="false">
                            Wiki
                            <img :src="downArrWhite" alt="down-arrow" class="arrow ms-1"
                                :class="darkMode ? 'd-none' : 'd-lg-block d-none'" />
                            <img :src="downArrBlack" alt="down-arrow" class="arrow ms-1 d-block"
                                :class="darkMode ? 'd-lg-block' : 'd-lg-none'" />
                        </a>
                        <div class="p-2 px-1 mt-0 dropdown-menu dropdown-menu-animation border-radius-xl mt-lg-3"
                            aria-labelledby="dropdownMenuPages">
                            <div class="row d-none d-lg-block">
                                <div class="px-4 py-2 col-12">
                                    <div class="row">
                                        <div class="position-relative">
                                            <router-link v-if="user.role === 'admin'" :to="{ name: 'Wiki Master' }"
                                                class="dropdown-item border-radius-md">
                                                <span>{{ user.role === 'admin' ? 'All Wikis' : 'Wikis' }}</span>
                                            </router-link>
                                            <router-link v-if="user.role === 'admin'" :to="{ name: 'Add Wiki' }"
                                                class="dropdown-item border-radius-md">
                                                <span>Add Wiki</span>
                                            </router-link>
                                            <router-link :to="{ name: 'User Wiki' }" class="dropdown-item border-radius-md">
                                                <span>Wikis</span>
                                            </router-link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- responsive -->
                            <div class="d-lg-none">
                                <router-link v-if="user.role === 'admin'" :to="{ name: 'Wiki Master' }"
                                    class="dropdown-item border-radius-md">{{
                                        user.role === 'admin' ? 'All Wikis' : 'Wikis' }}</router-link>
                                <router-link v-if="user.role === 'admin'" :to="{ name: 'Add Wiki' }"
                                    class="dropdown-item border-radius-md">Add Wiki</router-link>
                                <router-link :to="{ name: 'User Wiki' }" class="dropdown-item border-radius-md">
                                    <span>Wikis</span>
                                </router-link>
                            </div>
                        </div>
                    </li>
                    <li class="mx-2 nav-item dropdown dropdown-hover" v-if="user.role === 'admin'">
                        <a id="dropdownMenuPages" role="button"
                            class="cursor-pointer nav-link ps-2 d-flex justify-content-between align-items-center"
                            :class="[darkModes]" data-bs-toggle="dropdown" aria-expanded="false">
                            Settings
                            <img :src="downArrWhite" alt="down-arrow" class="arrow ms-1"
                                :class="darkMode ? 'd-none' : 'd-lg-block d-none'" />
                            <img :src="downArrBlack" alt="down-arrow" class="arrow ms-1 d-block"
                                :class="darkMode ? 'd-lg-block' : 'd-lg-none'" />
                        </a>
                        <div class="p-2 px-1 mt-0 dropdown-menu dropdown-menu-animation border-radius-xl mt-lg-3"
                            aria-labelledby="dropdownMenuPages">
                            <div class="row d-none d-lg-block">
                                <div class="px-4 py-2 col-12">
                                    <div class="row">
                                        <div class="position-relative">
                                            <router-link :to="{ name: 'Categories' }"
                                                class="dropdown-item border-radius-md">
                                                <span>Categories</span>
                                            </router-link>
                                            <router-link :to="{ name: 'Departments' }"
                                                class="dropdown-item border-radius-md">
                                                <span>Departments</span>
                                            </router-link>
                                            <router-link :to="{ name: 'Job Functions' }"
                                                class="dropdown-item border-radius-md">
                                                <span>Job Functions</span>
                                            </router-link>
                                            <router-link :to="{ name: 'Users' }" class="dropdown-item border-radius-md">
                                                <span>Users</span>
                                            </router-link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- responsive -->
                            <div class="d-lg-none">
                                <router-link :to="{ name: 'Categories' }" class="dropdown-item border-radius-md">
                                    <span>Categories</span>
                                </router-link>
                                <router-link :to="{ name: 'Departments' }" class="dropdown-item border-radius-md">
                                    <span>Departments</span>
                                </router-link>
                                <router-link :to="{ name: 'Job Functions' }" class="dropdown-item border-radius-md">
                                    <span>Job Functions</span>
                                </router-link>
                                <router-link :to="{ name: 'Users' }" class="dropdown-item border-radius-md">
                                    <span>Users</span>
                                </router-link>
                            </div>
                        </div>
                    </li>
                </ul>
                <ul class="navbar-nav d-lg-block d-none">
                    <li class="nav-item">
                        <router-link :to="{ name: 'Dashboard' }"
                            class="mb-0 btn btn-sm btn-round me-1 bg-gradient-success">Dashboard</router-link>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
    <!-- End Navbar -->
</template>
  
<script>
/*eslint-disable*/
import downArrWhite from "@/assets/img/down-arrow-white.svg";
import downArrBlack from "@/assets/img/down-arrow-dark.svg";
import Office from "@/components/Icon/Office.vue";
import Shop from "@/components/Icon/Shop.vue";
import CustomerSupport from "@/components/Icon/CustomerSupport.vue";
import Document from "@/components/Icon/Document.vue";
import Spaceship from "@/components/Icon/Spaceship.vue";
import GettingStarted from "@/components/Icon/GettingStarted.vue";
import Box3d from "@/components/Icon/Box3d.vue";
import Settings from "@/components/Icon/Settings.vue";

export default {
    name: "FullScreenNavbar",
    components: {
        Office,
        Shop,
        CustomerSupport,
        Document,
        Spaceship,
        GettingStarted,
        Box3d,
        Settings
    },
    props: {
        btnBackground: {
            type: String,
            default: "",
        },
        isBlur: {
            type: String,
            default: "",
        },
        darkMode: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            downArrWhite,
            downArrBlack
        };
    },
    computed: {
        darkModes() {
            return {
                "text-dark": this.darkMode
            };
        },
        user() {
            const user = JSON.parse(localStorage.getItem('user'));
            return user;
        }
    }
};
</script>
  