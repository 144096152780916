<template>
    <template v-if="loaded">
        <div class="container top-0 position-sticky z-index-sticky">
            <div class="row">
                <div class="col-12">
                    <FullScreenNavbar is-blur="blur blur-rounded my-3 py-2 start-0 end-0 mx-4 shadow"
                        btn-background="bg-gradient-success" :dark-mode="true" />
                </div>
            </div>
        </div>
        <div class="mt-6 py-4 container-fluid w-100">
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="pb-0 card-header">
                            <div class="text-center">
                                <div>
                                    <h2 class="mb-4">Wikipedia</h2>
                                </div>
                            </div>
                        </div>
                        <div class="px-5 py-5 bg-gray-200">
                            <div class="input-group mb-2 ms-auto me-auto w-100">
                                <span class="input-group-text text-body"><i class="fas fa-search" aria-hidden="true"></i>
                                </span><input v-model="query" type="text" class="form-control" style="height: 5rem;"
                                    placeholder="Type here...">
                            </div>
                        </div>
                        <div class="px-lg-5 px-sm-2 mb-0" v-if="!viewingWiki">
                            <hr class="mt-5 mb-0 wiki-hr">
                        </div>
                        <div class="card-body">
                            <div class=" hide-scroll">
                                <div class="row">
                                    <div class="col-9" v-if="!viewingWiki">
                                        <div class="row">
                                            <template v-for="(category, index) in filteredCategories" :key=category>
                                                <hr class="my-3 horizontal dark" v-if="index !== 0 && index % 2 === 0">
                                                <div class="col-lg-6 col-md-6 col-12"
                                                    :class="{ 'right-border': index % 2 === 0 }">
                                                    <div class="wiki-container">
                                                        <h2 class="text-left mb-4 fw-light wiki-category"
                                                            style="margin-top: 1rem;">
                                                            {{ category.name }}</h2>
                                                        <ul class="wiki">
                                                            <li v-for="wiki in category.questions" :key="wiki">
                                                                <a class="wiki-question mt-2 fw-light"
                                                                    @click="viewWiki(wiki)">
                                                                    {{ wiki.name }}</a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </template>

                                        </div>
                                    </div>
                                    <div class="col-9" v-else>
                                        <div class="details">
                                            <div class="text-center policy-title">
                                                <h4>{{ currentWiki?.name }}</h4>
                                            </div>
                                        </div>
                                        <hr class="mt-3 mb-1 horizontal dark">
                                        <div class="row">
                                            <div class="col-12 px-4 py-3 mt-3">
                                                <TextEditor v-model="currentWiki.content" mode="view" label="Content"
                                                    :quill-id="'current_wiki' + currentWiki.id" no-label no-top-margin>
                                                </TextEditor>
                                            </div>
                                        </div>
                                        <hr class="my-4">
                                        <div class="align-self-end">
                                            <button class="btn btn-secondary btn-lg"
                                                @click.prevent="clearWiki">Back</button>
                                        </div>
                                    </div>
                                    <div class="col-3 left-border px-5 d-lg-block d-md-block d-none">
                                        <h4 class="text-left mb-4 fw-light wiki-category" style="margin-top: 1rem;">{{
                                            `All Categories` }}</h4>
                                        <ul class="wiki small-height">
                                            <li v-for="category in allCategories" :key="category" style="margin-top: 1rem;">
                                                <a class="category-title mt-2 fw-light">
                                                    {{ `${category.name} (${category.wikis?.length})` }}</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </template>
    <template v-else>
        <SimpleLoader large full></SimpleLoader>
    </template>
</template>

<script>
import TextEditor from '@/components/TextEditor.vue';
import SimpleLoader from '@/components/SimpleLoader.vue';
import FullScreenNavbar from "@/layout/FullScreenNavBar.vue";

import { mapMutations } from "vuex";

export default {
    name: "UserWiki",
    data() {
        return { wikis: [], query: '', loaded: false, allCategories: [], viewingWiki: false, currentWiki: {} }
    },
    computed: {
        categories() {
            const categories = this.wikis.map((ele) => { return ele.category.name })
            const uniqueCategories = [...new Set(categories)];

            let finalCategories = []
            uniqueCategories.forEach((ele) => {
                const obj = {
                    name: ele,
                    questions: this.wikis.filter((wiki) => wiki.category.name === ele)
                }
                if (obj.questions.length > 0)
                    finalCategories.push(obj)
            })

            return finalCategories
        },

        filteredCategories() {
            return this.categories.filter((ele) => {
                return ele.name.toLowerCase().includes(this.query.toLowerCase()) ||
                    ele.questions?.some((ele) => ele.name.toLowerCase().includes(this.query.toLowerCase()))
            });
        }
    },
    mounted() {
        const data = this.$http.get('api/wikis').then((res) => {
            this.wikis = res.data
        })

        const categories = this.$http.get('api/wikis/data').then((res) => {
            this.allCategories = res.data?.categories;
        });

        Promise.all([data, categories]).then(() => {
            this.loaded = true
        })
    },
    components: {
        TextEditor,
        FullScreenNavbar,
        SimpleLoader
    },
    created() {
        this.toggleEveryDisplay();
        this.toggleHideConfig();
        // body.classList.remove("bg-gray-100");
    },
    beforeUnmount() {
        this.toggleEveryDisplay();
        this.toggleHideConfig();
        // body.classList.add("bg-gray-100");
    },
    methods: {
        ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),
        viewWiki(wiki) {
            this.viewingWiki = true;
            this.currentWiki = wiki;
        },
        clearWiki() {
            this.viewingWiki = false;
            this.currentWiki = {};
        }
    },
};
</script>

<style scoped>
.wiki {
    padding: 0;
    list-style: none;
}

.wiki-question {
    display: block;
    font-size: 1.25rem;
    line-height: 1.4;
    color: #000;
    overflow: hidden;
    text-decoration: none;
    cursor: pointer;
}

.wiki-question:hover {
    color: #67b108;
    text-decoration: none;
    outline: none;
}

.category-title {
    display: block;
    font-size: 1.25rem;
    line-height: 1.4;
    color: #000;
    overflow: hidden;
    text-decoration: none;
    cursor: pointer;
}

.category-title:hover {
    color: #67b108;
    text-decoration: none;
    outline: none;
}

.wiki-question::before {
    content: "";
    width: 1.4em;
    height: 1.4em;
    margin-left: 10px;
    float: right;
    background-image: url(data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2213%22%20height%3D%2213%22%20viewBox%3D%220%200%2013%2013%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Crect%20fill%3D%22%2364676d%22%20width%3D%2213%22%20height%3D%221%22%20x%3D%220%22%20y%3D%226%22%20%2F%3E%0A%20%20%20%20%3Crect%20fill%3D%22%2364676d%22%20width%3D%221%22%20height%3D%2213%22%20x%3D%226%22%20y%3D%220%22%20%2F%3E%0A%3C%2Fsvg%3E);
    background-repeat: no-repeat;
    background-position: 50% 50%;
}

.wiki-container {
    box-sizing: content-box;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    /* padding-left: 15px;
    padding-right: 15px; */
    padding-left: 40px;
    padding-right: 40px;
}

.wiki-container-xsmall {
    max-width: 750px;
    /* max-height: 60vh;
    overflow-y: scroll; */
    margin-left: auto;
    margin-right: auto;
}

.wiki-hr {
    box-sizing: content-box;
    height: 0;
    overflow: visible;
    text-align: center;
    margin: 0 0 20px 0;
    border: 0;
    border-top: 1px solid #f1f1f1;
    /* max-width: 750px; */
}

.wiki-answer {
    margin-top: 20px;
}

.wiki-category {
    margin-top: 3rem;
    color: #67b108;
}

.slide-fade-enter-active {
    transition: all .3s ease;
}

.slide-fade-leave-active {
    transition: all .1s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
    /* transform: translateX(10px); */
    opacity: 0;
}

.right-border {
    border-right: 1px solid #f1f1f1;
}

.left-border {
    border-left: 1px solid #f1f1f1;
}

.small-height {
    max-height: 300px;
    overflow-y: auto;
}
</style>