<template>
    <div class="py-4 container-fluid vh-screen-82">
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="pb-0 card-header">
                        <div class="row">
                            <div class="col-8"></div>
                            <div class="col-4">
                                <div class="input-group my-4">
                                    <span class="input-group-text text-body"><i class="fas fa-search"
                                            aria-hidden="true"></i>
                                    </span><input v-model="query" type="text" class="form-control"
                                        placeholder="Type here...">
                                </div>
                            </div>
                        </div>
                        <div class="d-lg-flex">
                            <div>
                                <h5 class="mb-0">All Users</h5>
                            </div>
                            <div class="my-auto mt-4 ms-auto mt-lg-0">
                                <div class="my-auto ms-auto">
                                    <button class="mb-0 btn btn-success btn-md"
                                        @click="$router.push({ name: 'Add User' })">+&nbsp; New
                                        User</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="px-0 pb-0 card-body">
                        <div class="table-responsive">
                            <table id="users-list" class="table table-flush table-hover">
                                <thead class="thead-light">
                                    <tr>
                                        <th class="text-center">Name</th>
                                        <th class="text-center">Email</th>
                                        <th class="text-center">Department</th>
                                        <th class="text-center">Role</th>
                                        <th class="text-center">Status</th>
                                        <th class="text-center">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="in_user in filteredUsers" :key="user.id" class="py-3">
                                        <td class="text-center">
                                            <h6 class="my-auto ms-3">{{ in_user.name }}</h6>
                                        </td>
                                        <td class="text-sm text-center">{{ in_user.email }}</td>
                                        <td class="text-sm text-center">{{ in_user.department?.name }}</td>
                                        <td class="text-sm text-center">{{ in_user.role }}</td>
                                        <td class="text-center">
                                            <span class="badge badge-sm"
                                                :class="{ 'badge-danger': in_user.status == 0, 'badge-success': in_user.status == 1 }">{{
                                                    in_user.status == 1 ? 'Active' : 'In-Active' }}</span>
                                        </td>
                                        <td class="text-sm text-center">
                                            <!-- <a :id="'view_' + in_user.id" data-bs-toggle="tooltip"
                                                data-bs-original-title="Preview User">
                                                <i class="fas fa-eye text-secondary"
                                                    @click="$router.push({ name: 'View User', params: { id: in_user.id } })"></i>
                                            </a> -->
                                            <a v-if="user.role === 'admin'" :id="'edit_' + in_user.id" class="mx-3"
                                                data-bs-toggle="tooltip" data-bs-original-title="Edit User"
                                                @click="$router.push({ name: 'Edit User', params: { id: in_user.id } })">
                                                <i class="fas fa-user-edit text-secondary"></i>
                                            </a>
                                            <a v-if="user.role === 'admin'" :id="'delete_' + in_user.id"
                                                data-bs-toggle="tooltip" data-bs-original-title="Delete User"
                                                @click=deleteUser(in_user.id)>
                                                <i class="fas fa-trash text-secondary"></i>
                                            </a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "UserMaster",
    data() {
        const user = JSON.parse(localStorage.getItem('user'));

        return { users: [], query: '', user }
    },
    computed: {
        filteredUsers() {
            return this.users.filter((ele) => {
                return ele.name.toLowerCase().includes(this.query.toLowerCase()) ||
                    ele.department.name.toLowerCase().includes(this.query.toLowerCase());
            });
        }
    },
    mounted() {
        this.$http.get('api/users').then((res) => {
            this.users = res.data
        })
    },
    methods: {
        deletePolicy(id) {
            this.$alert.tableDelete({
                path: `/api/users/${id}`
            }).then((res) => {
                if (res.isConfirmed) {
                    this.$http.get('api/users').then((res) => {
                        this.policies = res.data
                    })
                }
            })
        }
    },
};
</script>