<template>
    <div class="py-4 container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="pb-0 card-header">
                        <div class="d-lg-flex">
                            <div>
                                <h5 class="mb-0">Add FAQ - {{ faq?.question }}</h5>
                            </div>
                        </div>
                    </div>
                    <div class="px-0 pb-0 card-body">
                        <div class="p-5 pt-0">
                            <div class="row">
                                <div class="col-6">
                                    <Input v-model="faq.question" type="text" label="Qestion"></Input>
                                </div>
                                <div class="col-6">
                                    <Choice v-model="faq.category_id" :options="data.categories" label="Category"
                                        choice-id="faq_categories">
                                    </Choice>
                                </div>
                                <div class="col-6">
                                    <Choice v-model="faq.department_id" :options="data.departments" label="Department"
                                        choice-id="faq_departments">
                                    </Choice>
                                </div>
                                <div class="col-6">
                                    <Choice v-model="faq.status" :options="statuses" label="Status" :searchable="false"
                                        choice-id="faq_status">
                                    </Choice>
                                </div>
                                <div class="col-7">
                                    <div class="form-check form-switch ps-0 ms-auto mt-3 mb-0">
                                        <input id="general" class="form-check-input ps-0 ms-auto" type="checkbox"
                                            v-model="faq.general">
                                        <label class="form-check-label mb-0 text-body ms-3 text-truncate w-80"
                                            for="general">For all users? (General)</label>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <Choice v-model:tags="faq.job_functions" :options="data.job_functions" no-top-margin
                                        v-if="!faq.general" v-model:selected-tags="faq.jobFunctions"
                                        label="Targeted Job Functions" mode="tags" choice-id="faq_job_functions">
                                    </Choice>
                                </div>
                                <div class="col-12">
                                    <TextEditor v-model="faq.answer" mode="input" label="Content"></TextEditor>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-footer">
                        <hr>
                        <div class="align-self-end">
                            <button class="btn btn-success btn-lg me-3" @click.prevent="submit">Submit</button>
                            <button class="btn btn-secondary btn-lg" @click.prevent="$alert.back">Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import TextEditor from '@/components/TextEditor.vue';
import Input from '@/components/Input.vue';
import Choice from '@/components/Choice.vue';

export default {
    name: "AddFAQ",
    components: {
        TextEditor,
        Input,
        Choice
    },
    data() {
        return { faq: {}, data: {}, statuses: [{ id: 'published', name: 'Published' }, { id: 'draft', name: 'Draft' }, { id: 'unpublished', name: 'Unpublished' }] }
    },
    mounted() {
        this.$http.get('api/faqs/data')
            .then((res) => {
                this.data = res.data
            })
    },
    methods: {
        submit() {
            this.$http.post('api/faqs', this.faq)
                .then((res) => {
                    this.$alert.showSwal({
                        type: "success",
                        message: res.data.message,
                        width: 350,
                    });
                    this.$router.push({ name: 'FAQ Master' })
                }).catch((res) => {
                    if (res.data) {
                        this.$alert.showSwal({
                            type: "error",
                            message: res.data?.message,
                            width: 350,
                        });
                    }
                })
        }
    }
}
</script>