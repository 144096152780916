<!-- eslint-disable vue/no-mutating-props -->
<template>
    <div v-if="mode === 'input'">
        <div class="col-12">
            <label :class="noTopMargin ? '' : 'mt-4'">{{ label }}</label>
            <select :id="choiceId" v-model="localModelValue" class="form-control" :name="choiceId"
                @change="$emit('update:modelValue', localModelValue)">
            </select>
        </div>
    </div>
    <div v-if="mode === 'tags'">
        <div class="col-12">
            <label :class="noTopMargin ? '' : 'mt-4'">{{ label }}</label>
            <select :id="choiceId" v-model="localSelectedTags" class="form-control" :name="choiceId" multiple
                @change="$emit('update:selectedTags', localSelectedTags)">
            </select>
        </div>
    </div>
</template>

<script>
import Choices from "choices.js";

export default {
    name: "Choice",
    props: {
        // eslint-disable-next-line vue/require-default-prop
        modelValue: null,
        tags: {
            type: Array,
            default: () => []
        },
        selectedTags: {
            type: Array,
            default: () => []
        },
        options: {
            type: Array,
            default: () => {
                return [];
            }
        },
        choiceId: {
            type: String,
            default: 'choice'
        },
        label: {
            type: String,
            default: 'Drop Down'
        },
        mode: {
            type: String,
            default: 'input'
        },
        searchable: {
            type: Boolean,
            default: true
        },
        noTopMargin: {
            type: Boolean,
            default: false
        }
    },
    emits: ['update:modelValue', 'update:selectedTags'],
    data() {
        return { parsedOptions: [], choice: null, localSelectedTags: [], localModelValue: this.modelValue }
    },
    watch: {
        tags: {
            immediate: true,
            handler(newTags) {
                if (this.mode === 'tags') {
                    if (newTags.length > 0) {
                        this.localSelectedTags = newTags.map(ele => (ele.id))
                        this.$emit('update:selectedTags', this.localSelectedTags)
                    }
                }
            }
        },
        options: {
            immediate: true, // Populate on component creation
            handler(newOptions) {
                if (this.mode === 'input') {
                    this.parsedOptions = newOptions.map(option => ({
                        label: option.name,
                        value: option.id,
                        selected: option.id == this.modelValue ? true : false
                    }));

                } else if (this.mode === 'tags') {
                    if (newOptions.length > 0) {
                        this.parsedOptions = newOptions.map(option => ({
                            label: option.name,
                            value: option.id,
                            selected: this.localSelectedTags.includes(option.id) ? true : false
                        }));
                    }
                }
                if (this.choice) {
                    this.choice.setChoices(this.parsedOptions);
                }
            }
        },
    },
    mounted() {
        if (document.getElementById(this.choiceId)) {
            let element = document.getElementById(this.choiceId);
            this.choice = new Choices(element, {
                searchEnabled: this.searchable,
                removeItemButton: true,
                allowHTML: false
            });
            this.choice.setChoices(this.parsedOptions);
        }
    },
};
</script>
