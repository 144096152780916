import { createApp } from 'vue'
import VueSweetalert2 from "vue-sweetalert2";
import showSwal from "/src/mixins/showSwal.js";


import App from './App.vue'
import router from './router'
import store from './store'
import axios from './axios';
import Macroify from "./macroify";


const token = localStorage.getItem('token')
if (token !== null) {
    axios.defaults.headers = { Authorization: `Bearer ${token}` }
}


// Macroiyf App Instance
const macroify = createApp(App);

// Global Props
macroify.config.globalProperties.$http = axios;
macroify.config.globalProperties.$alert = showSwal.methods;

// Libararies to be used
macroify.use(store);
macroify.use(router);
macroify.use(Macroify);
macroify.use(VueSweetalert2);

// DOM Mount ID
macroify.mount('#app');
