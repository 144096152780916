<template>
    <template v-if="loaded">
        <div class="container top-0 position-sticky z-index-sticky">
            <div class="row">
                <div class="col-12">
                    <FullScreenNavbar is-blur="blur blur-rounded my-3 py-2 start-0 end-0 mx-4 shadow"
                        btn-background="bg-gradient-success" :dark-mode="true" />
                </div>
            </div>
        </div>
        <div class="mt-6 py-4 container-fluid w-lg-75">
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="pb-0 card-header">
                            <div class="text-center">
                                <div>
                                    <h3 class="mb-4">Frequently Asked Questions</h3>
                                    <div class="input-group mb-2 ms-auto me-auto mt-5" style="max-width: 750px;">
                                        <span class="input-group-text text-body"><i class="fas fa-search"
                                                aria-hidden="true"></i>
                                        </span><input v-model="query" type="text" class="form-control"
                                            placeholder="Type here...">
                                    </div>
                                </div>
                            </div>
                            <div class="px-lg-9 px-sm-4 mb-0">
                                <hr class="mt-5 mb-0 faq-hr">
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="faq-container-xsmall hide-scroll">
                                <div class="faq-container" v-for="(category, index) in filteredCategories" :key=category>
                                    <h2 class="text-left mb-4 fw-light faq-category"
                                        :style="index === 0 ? 'margin-top: 1rem;' : ''">{{ category.name }}</h2>
                                    <ul class="faq">
                                        <li v-for="faq in category.questions" :key="faq">
                                            <a class="faq-question mt-4 fw-light"
                                                @click="faq.showAnswer = faq.showAnswer ? false : true">
                                                {{ faq.question }}</a>
                                            <transition name="slide-fade">
                                                <div class="faq-answer px-4" v-if="faq.showAnswer" aria-hidden="true">
                                                    <TextEditor v-model="faq.answer" mode="view" no-label no-top-margin>
                                                    </TextEditor>
                                                </div>
                                            </transition>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </template>
    <template v-else>
        <SimpleLoader large full></SimpleLoader>
    </template>
</template>

<script>
import TextEditor from '@/components/TextEditor.vue';
import SimpleLoader from '@/components/SimpleLoader.vue';
import FullScreenNavbar from "@/layout/FullScreenNavBar.vue";

import { mapMutations } from "vuex";
const body = document.getElementsByTagName("body")[0];

export default {
    name: "FAQMaster",
    data() {
        return { faqs: [], query: '', loaded: false }
    },
    computed: {
        categories() {
            const categories = this.faqs.map((ele) => { return ele.category.name })
            const uniqueCategories = [...new Set(categories)];

            let finalCategories = []
            uniqueCategories.forEach((ele) => {
                const obj = {
                    name: ele,
                    questions: this.faqs.filter((faq) => faq.category.name === ele)
                }
                if (obj.questions.length > 0)
                    finalCategories.push(obj)
            })

            return finalCategories
        },

        filteredCategories() {
            return this.categories.filter((ele) => {
                return ele.name.toLowerCase().includes(this.query.toLowerCase()) ||
                    ele.questions?.some((ele) => ele.question.toLowerCase().includes(this.query.toLowerCase()))
            });
        }
    },
    mounted() {
        this.$http.get('api/faqs').then((res) => {
            this.faqs = res.data
            this.loaded = true
        })
    },
    components: {
        TextEditor,
        FullScreenNavbar,
        SimpleLoader
    },
    created() {
        this.toggleEveryDisplay();
        this.toggleHideConfig();
        // body.classList.remove("bg-gray-100");
    },
    beforeUnmount() {
        this.toggleEveryDisplay();
        this.toggleHideConfig();
        // body.classList.add("bg-gray-100");
    },
    methods: {
        ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),
    },
};
</script>

<style>
.faq {
    padding: 0;
    list-style: none;
}

.faq-question {
    display: block;
    font-size: 1.25rem;
    line-height: 1.4;
    color: #000;
    overflow: hidden;
    text-decoration: none;
    cursor: pointer;
}

.faq-question:hover {
    color: #67b108;
    text-decoration: none;
    outline: none;
}

.faq-question::before {
    content: "";
    width: 1.4em;
    height: 1.4em;
    margin-left: 10px;
    float: right;
    background-image: url(data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2213%22%20height%3D%2213%22%20viewBox%3D%220%200%2013%2013%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Crect%20fill%3D%22%2364676d%22%20width%3D%2213%22%20height%3D%221%22%20x%3D%220%22%20y%3D%226%22%20%2F%3E%0A%20%20%20%20%3Crect%20fill%3D%22%2364676d%22%20width%3D%221%22%20height%3D%2213%22%20x%3D%226%22%20y%3D%220%22%20%2F%3E%0A%3C%2Fsvg%3E);
    background-repeat: no-repeat;
    background-position: 50% 50%;
}

.faq-container {
    box-sizing: content-box;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    /* padding-left: 15px;
    padding-right: 15px; */
    padding-left: 40px;
    padding-right: 40px;
}

.faq-container-xsmall {
    max-width: 750px;
    /* max-height: 60vh;
    overflow-y: scroll; */
    margin-left: auto;
    margin-right: auto;
}

.faq-hr {
    box-sizing: content-box;
    height: 0;
    overflow: visible;
    text-align: center;
    margin: 0 0 20px 0;
    border: 0;
    border-top: 1px solid #f1f1f1;
    /* max-width: 750px; */
}

.faq-answer {
    margin-top: 20px;
}

.faq-category {
    margin-top: 3rem;
    color: #67b108;
}

/* @media (min-width: 960px) .faq-container {
    padding-left: 40px;
    padding-right: 40px;
}

@media (min-width: 640px) .faq-container {
    padding-left: 30px;
    padding-right: 30px;
} */

.slide-fade-enter-active {
    transition: all .3s ease;
}

.slide-fade-leave-active {
    transition: all .1s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
    /* transform: translateX(10px); */
    opacity: 0;
}
</style>