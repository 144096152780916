<template>
    <div class="py-4 container-fluid vh-screen-82">
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="pb-0 card-header">
                        <div class="row">
                            <div class="col-8"></div>
                            <div class="col-4">
                                <div class="input-group mb-4">
                                    <span class="input-group-text text-body"><i class="fas fa-search"
                                            aria-hidden="true"></i>
                                    </span><input v-model="query" type="text" class="form-control"
                                        placeholder="Type here...">
                                </div>
                            </div>
                        </div>
                        <div class="d-lg-flex">
                            <div>
                                <h5 class="mb-0">Policy Books</h5>
                            </div>
                            <div class="my-auto mt-4 ms-auto mt-lg-0">
                                <div class="my-auto ms-auto">
                                    <button v-if="user.role === 'admin'" class="mb-0 btn btn-success btn-md"
                                        @click="$router.push({ name: 'Add Policy' })">+&nbsp; New
                                        Policy Book</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="px-0 pb-0 card-body">
                        <div class="table-responsive">
                            <table id="policies-list" class="table table-flush table-hover">
                                <thead class="thead-light">
                                    <tr>
                                        <th class="text-center">Name</th>
                                        <th class="text-center">Category</th>
                                        <th class="text-center">Description</th>
                                        <th class="text-center">Policies</th>
                                        <th class="text-center">Status</th>
                                        <th class="text-center">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="policy in filteredPolicies" :key="policy.id" class="py-3"
                                        @dblclick="$router.push({ name: 'View Policy', params: { id: policy.id } })">
                                        <td class="text-center">
                                            <h6 class="my-auto ms-3">{{ policy.name }}</h6>
                                        </td>
                                        <td class="text-sm text-center">{{ policy.category.name }}</td>
                                        <td class="text-sm text-center wrap-text">{{ policy.description }}</td>
                                        <td class="text-sm text-center">{{ policy.policies.length }}</td>
                                        <td class="text-center">
                                            <span class="badge badge-sm"
                                                :class="{ 'badge-success': policy.status === 'published', 'badge-secondary': policy.status == 'unpublished', 'badge-info': policy.status === 'draft' }">{{
                                                    policy.status.toUpperCase() }}</span>
                                        </td>
                                        <td class="text-sm text-center">
                                            <a :id="'view_' + policy.id" data-bs-toggle="tooltip"
                                                data-bs-original-title="Preview product">
                                                <i class="fas fa-eye text-secondary" @click="push(policy.id)"></i>
                                            </a>
                                            <a v-if="user.role === 'admin'" :id="'edit_' + policy.id" class="mx-3"
                                                data-bs-toggle="tooltip" data-bs-original-title="Edit product"
                                                @click="$router.push({ name: 'Edit Policy', params: { id: policy.id } })">
                                                <i class="fas fa-user-edit text-secondary"></i>
                                            </a>
                                            <a v-if="user.role === 'admin'" :id="'delete_' + policy.id"
                                                data-bs-toggle="tooltip" data-bs-original-title="Delete product"
                                                @click=deletePolicy(policy.id)>
                                                <i class="fas fa-trash text-secondary text-danger"></i>
                                            </a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// import { DataTable } from "simple-datatables";
// import setTooltip from "@/assets/js/tooltip.js";
import { mapMutations } from 'vuex';

export default {
    name: "PolicyMaster",
    data() {
        const user = JSON.parse(localStorage.getItem('user'));
        return {
            policies: [],
            query: '',
            user
        }
    },
    computed: {
        filteredPolicies() {
            return this.policies.filter((ele) => {
                return ele.name.toLowerCase().includes(this.query.toLowerCase()) ||
                    ele.category.name.toLowerCase().includes(this.query.toLowerCase())
            });
        },
        categories() {
            return [...new Set(this.filteredPolicies.map(ele => ele.category))];
        }
    },
    mounted() {
        this.$http.get('api/policies').then((res) => {
            // console.log(res.data, 'res')
            this.policies = res.data
            this.toggleLoading = false;
        })
    },
    methods: {
        ...mapMutations(['toggleLoading']),
        push(id) {
            console.log('here')
            this.$router.push({ name: 'View Policy', params: { id: id } })
        },
        deletePolicy(id) {
            this.$alert.tableDelete({
                path: `/api/policies/${id}`
            }).then((res) => {
                if (res.isConfirmed) {
                    this.$http.get('api/policies').then((res) => {
                        this.policies = res.data
                    })
                }
            })
        }
    },
};
</script>

<style scoped>
.wrap-text {
    white-space: normal;
}
</style>