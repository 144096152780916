<template>
    <div class="py-4 container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="pb-0 card-header">
                        <div class="d-lg-flex">
                            <div>
                                <h5 class="mb-0">Add Category - {{ category?.name }}</h5>
                            </div>
                        </div>
                    </div>
                    <div class="px-0 pb-0 card-body">
                        <div class="p-5 pt-0">
                            <div class="row">
                                <div class="col-6">
                                    <Input v-model="category.name" type="text" label="Category Name"></Input>
                                </div>
                                <div class="col-6">
                                    <Choice v-model="category.type" :options="types" label="Category Type"
                                        choice-id="category_type">
                                    </Choice>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-footer">
                        <hr>
                        <div class="align-self-end">
                            <button class="btn btn-success btn-lg me-3" @click.prevent="submit">Submit</button>
                            <button class="btn btn-secondary btn-lg" @click.prevent="$alert.back">Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Input from '@/components/Input.vue';
import Choice from '@/components/Choice.vue';

export default {
    name: "AddCategory",
    components: {
        Input,
        Choice
    },
    data() {
        return {
            category: {}, types: [
                { name: 'Standard Operation Procedure', id: 'SOP' },
                { name: 'Learning Management System', id: 'LMS' },
                { name: 'Frequently Asked Questions', id: 'FAQ' },
                { name: 'Wikipedia', id: 'WIKI' },
                { name: 'Policies', id: 'Policy' },
                { name: 'Objectives and Key Results', id: 'OKR' }
            ]
        }
    },
    methods: {
        submit() {
            this.$http.post('api/categories', this.category)
                .then((res) => {
                    this.$alert.showSwal({
                        type: "success",
                        message: res.data.message,
                        width: 350,
                    });
                    this.$router.push({ name: 'Categories' })
                }).catch((res) => {
                    if (res.data) {
                        this.$alert.showSwal({
                            type: "error",
                            message: res.data?.message,
                            width: 350,
                        });
                    }
                })
        },
    }
}
</script>