<template>
    <div class="py-4 container-fluid vh-screen-82">
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="pb-0 card-header">
                        <div class="row">
                            <div class="col-8"></div>
                            <div class="col-4">
                                <div class="input-group mb-4">
                                    <span class="input-group-text text-body"><i class="fas fa-search"
                                            aria-hidden="true"></i>
                                    </span><input v-model="query" type="text" class="form-control"
                                        placeholder="Type here...">
                                </div>
                            </div>
                        </div>
                        <div class="d-lg-flex">
                            <div>
                                <h5 class="mb-0">All Wiki Records</h5>
                            </div>
                            <div class="my-auto mt-4 ms-auto mt-lg-0">
                                <div class="my-auto ms-auto">
                                    <button v-if="user.role === 'admin'" class="mb-0 btn btn-success btn-md"
                                        @click="$router.push({ name: 'Add Wiki' })">+&nbsp; New
                                        Wiki</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="px-0 pb-0 card-body">
                        <div class="table-responsive">
                            <table id="wikis-list" class="table table-flush table-hover">
                                <thead class="thead-light">
                                    <tr>
                                        <th class="text-center">Name</th>
                                        <th class="text-center">Category</th>
                                        <th class="text-center">Department</th>
                                        <th class="text-center">Status</th>
                                        <th class="text-center">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="wiki in filteredWikis" :key="wiki.id" class="py-3">
                                        <td class="text-center">
                                            <h6 class="my-auto ms-3">{{ wiki.name }}</h6>
                                        </td>
                                        <td class="text-sm text-center">{{ wiki.category.name }}</td>
                                        <td class="text-sm text-center">{{ wiki.department.name }}</td>
                                        <td class="text-center">
                                            <span class="badge badge-sm"
                                                :class="{ 'badge-success': wiki.status === 'published', 'badge-secondary': wiki.status == 'unpublished', 'badge-info': wiki.status === 'draft' }">{{
                                                    wiki.status.toUpperCase() }}</span>
                                        </td>
                                        <td class="text-sm text-center">
                                            <a :id="'view_' + wiki.id" data-bs-toggle="tooltip"
                                                data-bs-original-title="Preview product">
                                                <i class="fas fa-eye text-secondary" @click="push(wiki.id)"></i>
                                            </a>
                                            <a v-if="user.role === 'admin'" :id="'edit_' + wiki.id" class="mx-3"
                                                data-bs-toggle="tooltip" data-bs-original-title="Edit product"
                                                @click="$router.push({ name: 'Edit Wiki', params: { id: wiki.id } })">
                                                <i class="fas fa-user-edit text-secondary"></i>
                                            </a>
                                            <a v-if="user.role === 'admin'" :id="'delete_' + wiki.id"
                                                data-bs-toggle="tooltip" data-bs-original-title="Delete product"
                                                @click=deleteWiki(wiki.id)>
                                                <i class="fas fa-trash text-danger"></i>
                                            </a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// import { DataTable } from "simple-datatables";
// import setTooltip from "@/assets/js/tooltip.js";

export default {
    name: "WikiMaster",
    data() {
        const user = JSON.parse(localStorage.getItem('user'));
        return { wikis: [], wikis2: [], query: '', user }
    },
    computed: {
        filteredWikis() {
            return this.wikis.filter((ele) => {
                return ele.name.toLowerCase().includes(this.query.toLowerCase()) ||
                    ele.department.name.toLowerCase().includes(this.query.toLowerCase()) ||
                    ele.category.name.toLowerCase().includes(this.query.toLowerCase())
            });
        }
    },
    mounted() {
        this.$http.get('api/wikis').then((res) => {
            this.wikis = res.data
        })
    },
    methods: {
        push(id) {
            console.log('here')
            this.$router.push({ name: 'View Wiki', params: { id: id } })
        },
        deleteWiki(id) {
            this.$alert.tableDelete({
                path: `/api/wikis/${id}`
            }).then((res) => {
                if (res.isConfirmed) {
                    this.$http.get('api/wikis').then((res) => {
                        this.wikis = res.data
                    })
                }
            })
        }
    },
};
</script>