<template>
  <sidenav v-if="showSidenav" :custom-class="color" :class="[isTransparent, isRTL ? 'fixed-end' : 'fixed-start']" />
  <main class="main-content position-relative max-height-vh-100 h-100 border-radius-lg">
    <!-- nav -->
    <navbar v-if="showNavbar" :class="[isNavFixed ? navbarFixed : '', isAbsolute ? absolute : '']"
      :text-white="isAbsolute ? 'text-white opacity-8' : ''" :min-nav="navbarMinimize" />
    <template v-if="isLoading">
      <simple-loader large></simple-loader>
    </template>
    <template v-else>
      <router-view />
    </template>
    <app-footer v-show="showFooter" />
    <!-- <configurator :toggle="toggleConfigurator" :class="[showConfig ? 'show' : '', hideConfigButton ? 'd-none' : '']" /> -->
  </main>
</template>
<script>
import Sidenav from "@/layout/Sidenav";
import Navbar from "@/layout/Navbar.vue";
import AppFooter from "@/layout/Footer.vue";
// import Loader from "@/components/Loader.vue"
import SimpleLoader from "@/components/SimpleLoader.vue"
import { mapMutations, mapState } from "vuex";
export default {
  name: "App",
  components: {
    Sidenav,
    Navbar,
    AppFooter,
    // Loader,
    SimpleLoader,
  },
  computed: {
    ...mapState([
      "isTransparent",
      "isRTL",
      "isNavFixed",
      "isAbsolute",
      "isLoading",
      "navbarFixed",
      "absolute",
      "color",
      "showSidenav",
      "showNavbar",
      "showFooter",
      "showConfig",
      "hideConfigButton",
    ]),
  },
  beforeMount() {
    this.$store.state.isTransparent = "bg-white";
    this.$store.state.isNavFixed = true
  },
  methods: {
    ...mapMutations(["toggleConfigurator", "navbarMinimize"]),
  },
};
</script>
<style>
/* Hiding scrollbar for Chrome, Safari and Opera */
.hide-scroll {
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
  scrollbar-width: none;
  /* Firefox */
}

.hide-scroll::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */
}

.vh-screen {
  min-height: 100vh
}

.vh-screen-82 {
  min-height: 82vh
}
</style>