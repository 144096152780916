import { createRouter, createWebHistory } from "vue-router";
import store from "@/store";

import Settings from "@/views/Settings.vue";
import Dashboard from "@/views/Dashboard.vue";
import SignIn from "@/views/auth/SignIn.vue";

// Standered Operation Procedures
import SOP from "@/views/sop/SOP.vue";
import AddSOP from "@/views/sop/AddSOP.vue";
import EditSOP from "@/views/sop/EditSOP.vue";
import ViewSOP from "@/views/sop/ViewSOP.vue";

// Frequently Asked Questions
import FAQ from "@/views/faq/FAQ.vue";
import AddFAQ from "@/views/faq/AddFAQ.vue";
import UserFAQ from "@/views/faq/UserFAQ.vue";
import EditFAQ from "@/views/faq/EditFAQ.vue";
import ViewFAQ from "@/views/faq/ViewFAQ.vue";

// Wikipedia
import Wiki from "@/views/wiki/Wiki.vue";
import UserWiki from "@/views/wiki/UserWiki.vue";
import AddWiki from "@/views/wiki/AddWiki.vue";
import EditWiki from "@/views/wiki/EditWiki.vue";
import ViewWiki from "@/views/wiki/ViewWiki.vue";

// Policies
import Policies from "@/views/policies/Policies.vue";
import AddPolicy from "@/views/policies/AddPolicy.vue";
import EditPolicy from "@/views/policies/EditPolicy.vue";
import ViewPolicy from "@/views/policies/ViewPolicy.vue";
import PolicyAssignment from "@/views/policies/PolicyAssignment.vue";
import ViewPolicyAssignment from "@/views/policies/ViewPolicyAssignment.vue";

// Categories
import Categories from "@/views/settings/categories/Categories.vue";
import AddCategory from "@/views/settings/categories/AddCategory.vue";
import EditCategory from "@/views/settings/categories/EditCategory.vue";
import ViewCategory from "@/views/settings/categories/ViewCategory.vue";

// Departments
import Departments from "@/views/settings/departments/Departments.vue";
import AddDepartment from "@/views/settings/departments/AddDepartment.vue";
import EditDepartment from "@/views/settings/departments/EditDepartment.vue";
import ViewDepartment from "@/views/settings/departments/ViewDepartment.vue";

// Job Functions
import JobFunctions from "@/views/settings/job-functions/JobFunctions.vue";
import AddJobFunction from "@/views/settings/job-functions/AddJobFunction.vue";
import EditJobFunction from "@/views/settings/job-functions/EditJobFunction.vue";
import ViewJobFunction from "@/views/settings/job-functions/ViewJobFunction.vue";

// Users
import Users from "@/views/settings/users/Users.vue";
import AddUser from "@/views/settings/users/AddUser.vue";
import EditUser from "@/views/settings/users/EditUser.vue";
import ViewUser from "@/views/settings/users/ViewUser.vue";

// Middlewares
import auth from "@/middlewares/auth.js";
import admin from "@/middlewares/admin.js";
import guest from "@/middlewares/guest.js";
import superadmin from "@/middlewares/superadmin.js";

const routes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
    meta: {
      noLoading: true,
    },
  },
  {
    path: "/",
    name: "home",
    redirect: "/dashboard",
  },
  // Auth Paths
  {
    path: "/login",
    name: "Login",
    component: SignIn,
    meta: {
      middleware: [guest],
      noLoading: true,
    },
  },
  // Super Admin Paths
  {
    path: "/admin",
    name: "Super Dashboard",
    component: () => import("@/admin/Dashboard.vue"),
    meta: {
      middleware: [auth, superadmin],
      noLoading: true,
    },
  },
  {
    path: "/admin/settings/users",
    name: "Super Users",
    component: () => import("@/admin/users/Users.vue"),
    meta: {
      middleware: [auth, superadmin],
    },
  },
  {
    path: "/admin/settings/users/add",
    name: "Add Super User",
    component: () => import("@/admin/users/AddUser.vue"),
    meta: {
      middleware: [auth, superadmin],
    },
  },
  {
    path: "/admin/settings/users/:id/edit",
    name: "Edit Super User",
    component: () => import("@/admin/users/EditUser.vue"),
    meta: {
      middleware: [auth, superadmin],
    },
  },
  {
    path: "/admin/tenants",
    name: "Tenants",
    component: () => import("@/admin/tenants/Tenants.vue"),
    meta: {
      middleware: [auth, superadmin],
    },
  },
  {
    path: "/admin/tenants/add",
    name: "Add Tenant",
    component: () => import("@/admin/tenants/AddTenant.vue"),
    meta: {
      middleware: [auth, superadmin],
    },
  },
  {
    path: "/admin/tenants/:id/edit",
    name: "Edit Tenant",
    component: () => import("@/admin/tenants/EditTenant.vue"),
    meta: {
      middleware: [auth, superadmin],
    },
  },
  // Tenant Paths
  {
    path: "/sop",
    name: "SOP Master",
    component: SOP,
  },
  {
    path: "/sop/add",
    name: "Add SOP",
    component: AddSOP,
    meta: {
      middleware: [auth, admin],
    },
  },
  {
    path: "/sop/:id",
    name: "View SOP",
    component: ViewSOP,
  },
  {
    path: "/sop/:id/edit",
    name: "Edit SOP",
    component: EditSOP,
    meta: {
      middleware: [auth, admin],
    },
  },
  {
    path: "/knowledge/faq",
    name: "FAQ Master",
    component: FAQ,
    meta: {
      middleware: [auth, admin],
    },
  },
  {
    path: "/knowledge/faqs",
    name: "User FAQ",
    component: UserFAQ,
    meta: {
      noLoading: true,
    },
  },
  {
    path: "/knowledge/faq/add",
    name: "Add FAQ",
    component: AddFAQ,
    meta: {
      middleware: [auth, admin],
    },
  },
  {
    path: "/knowledge/faq/:id",
    name: "View FAQ",
    component: ViewFAQ,
    meta: {
      middleware: [auth, admin],
    },
  },
  {
    path: "/knowledge/faq/:id/edit",
    name: "Edit FAQ",
    component: EditFAQ,
    meta: {
      middleware: [auth, admin],
    },
  },
  {
    path: "/knowledge/wiki",
    name: "Wiki Master",
    component: Wiki,
    meta: {
      middleware: [auth, admin],
    },
  },
  {
    path: "/knowledge/wikis",
    name: "User Wiki",
    component: UserWiki,
    meta: {
      noLoading: true,
    },
  },
  {
    path: "/knowledge/wiki/add",
    name: "Add Wiki",
    component: AddWiki,
    meta: {
      middleware: [auth, admin],
    },
  },
  {
    path: "/knowledge/wiki/:id",
    name: "View Wiki",
    component: ViewWiki,
    meta: {
      middleware: [auth, admin],
    },
  },
  {
    path: "/knowledge/wiki/:id/edit",
    name: "Edit Wiki",
    component: EditWiki,
    meta: {
      middleware: [auth, admin],
    },
  },
  {
    path: "/policy",
    name: "Policies Master",
    component: Policies,
    meta: {
      middleware: [auth, admin],
    },
  },
  {
    path: "/policy/add",
    name: "Add Policy",
    component: AddPolicy,
    meta: {
      middleware: [auth, admin],
    },
  },
  {
    path: "/policy/:id",
    name: "View Policy",
    component: ViewPolicy,
    meta: {
      noLoading: true,
    },
  },
  {
    path: "/policy/:id/edit",
    name: "Edit Policy",
    component: EditPolicy,
    meta: {
      middleware: [auth, admin],
    },
  },
  {
    path: "/policy/assigned",
    name: "Assigned Policies",
    component: PolicyAssignment,
  },
  {
    path: "/policy/assigned/:id",
    name: "View Assigned Policy",
    component: ViewPolicyAssignment,
    meta: {
      noLoading: true,
    },
  },
  {
    path: "/settings",
    name: "Settings",
    component: Settings,
    meta: {
      middleware: [auth, admin],
    },
  },
  {
    path: "/settings/categories",
    name: "Categories",
    component: Categories,
    meta: {
      middleware: [auth, admin],
    },
  },
  {
    path: "/settings/categories/add",
    name: "Add Category",
    component: AddCategory,
    meta: {
      middleware: [auth, admin],
    },
  },
  {
    path: "/settings/categories/:id/edit",
    name: "Edit Category",
    component: EditCategory,
    meta: {
      middleware: [auth, admin],
    },
  },
  {
    path: "/settings/categories/:id",
    name: "View Category",
    component: ViewCategory,
    meta: {
      middleware: [auth, admin],
    },
  },
  {
    path: "/settings/departments",
    name: "Departments",
    component: Departments,
    meta: {
      middleware: [auth, admin],
    },
  },
  {
    path: "/settings/departments/add",
    name: "Add Department",
    component: AddDepartment,
    meta: {
      middleware: [auth, admin],
    },
  },
  {
    path: "/settings/departments/:id/edit",
    name: "Edit Department",
    component: EditDepartment,
    meta: {
      middleware: [auth, admin],
    },
  },
  {
    path: "/settings/departments/:id",
    name: "View Department",
    component: ViewDepartment,
    meta: {
      middleware: [auth, admin],
    },
  },
  {
    path: "/settings/jobfunctions",
    name: "Job Functions",
    component: JobFunctions,
    meta: {
      middleware: [auth, admin],
    },
  },
  {
    path: "/settings/jobfunctions/add",
    name: "Add Job Function",
    component: AddJobFunction,
    meta: {
      middleware: [auth, admin],
    },
  },
  {
    path: "/settings/jobfunctions/:id/edit",
    name: "Edit Job Function",
    component: EditJobFunction,
    meta: {
      middleware: [auth, admin],
    },
  },
  {
    path: "/settings/jobfunctions/:id",
    name: "View Job Function",
    component: ViewJobFunction,
    meta: {
      middleware: [auth, admin],
    },
  },
  {
    path: "/settings/users",
    name: "Users",
    component: Users,
    meta: {
      middleware: [auth, admin],
    },
  },
  {
    path: "/settings/users/add",
    name: "Add User",
    component: AddUser,
    meta: {
      middleware: [auth, admin],
    },
  },
  {
    path: "/settings/users/:id/edit",
    name: "Edit User",
    component: EditUser,
    meta: {
      middleware: [auth, admin],
    },
  },
  {
    path: "/settings/users/:id",
    name: "View User",
    component: ViewUser,
    meta: {
      middleware: [auth, admin],
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
});

function nextFactory(context, middleware, index) {
  const subsequentMiddleware = middleware[index];
  if (!subsequentMiddleware) return context.next;

  return (...parameters) => {
    context.next(...parameters);
    const nextMiddleware = nextFactory(context, middleware, index + 1);
    subsequentMiddleware({ ...context, next: nextMiddleware });
  };
}

router.beforeEach((to, from, next) => {
  if (!to.meta.middleware) {
    to.meta.middleware = [auth];
  }

  const middleware = to.meta.middleware;

  const context = {
    from,
    next,
    router,
    to,
  };

  const nextMiddleware = nextFactory(context, middleware, 1);

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);

  // if (!to.meta.noLoading) {
  //   store.state.isLoading = true;
  //   setTimeout(() => {
  //     store.state.isLoading = false;
  //   }, 2000);
  // }

  return middleware[0]({ ...context, next: nextMiddleware });
});

export default router;
