<template>
    <div class="py-4 container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body center" :class="{ 'vh-screen-82': !full }" :style="full ? 'height: 93vh;' : ''">
                        <span class="spinner spinner-slow" :class="{ 'spinner-large': large }"></span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        large: {
            type: Boolean,
            default: false
        },
        full: {
            type: Boolean,
            default: false
        }
    }
}
</script>
<style>
.center {
    position: relative;
}

.center span {
    margin: 0;
    position: absolute;
    top: 40%;
    left: 45%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.spinner {
    /* Spinner size and color */
    width: 1.5rem;
    height: 1.5rem;
    border-top-color: #444;
    border-left-color: #444;

    /* Additional spinner styles */
    animation: spinner 400ms linear infinite;
    border-bottom-color: transparent;
    border-right-color: transparent;
    border-style: solid;
    border-width: 2px;
    border-radius: 50%;
    box-sizing: border-box;
    display: inline-block;
    vertical-align: middle;
}

.spinner-large {
    width: 5rem;
    height: 5rem;
    border-width: 6px;
}

/* Animation styles */
@keyframes spinner {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.spinner-slow {
    animation: spinner 1s linear infinite;
}
</style>