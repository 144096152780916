<template>
  <aside id="sidenav-main"
    class="my-3 overflow-auto border-0 sidenav navbar navbar-vertical navbar-expand-xs border-radius-xl"
    :class="isRTL ? 'me-3 rotate-caret' : 'ms-3'" style="overflow-x: hidden !important; z-index: 1021;">
    <div class="sidenav-header">
      <i id="iconSidenav"
        class="top-0 p-3 cursor-pointer fas fa-times text-secondary opacity-5 position-absolute end-0 d-none d-xl-none"
        aria-hidden="true"></i>
      <router-link class="m-0" to="/">
        <img :src="isPinned ? logo : smLogo" :class="{ 'logo-new': isPinned, 'logo-new-sm': !isPinned }"
          alt="main_logo" />
        <!-- <span class="ms-1 font-weight-bold">Macroify</span> -->
      </router-link>
    </div>
    <hr class="mt-0 horizontal dark" />
    <sidenav-list :card-bg="customClass" />
  </aside>
</template>
<script>
import SidenavList from "./SidenavList.vue";
import logo from "@/assets/img/Logo.png";
import smLogo from "@/assets/img/Logo2.png";
import { mapState } from "vuex";
export default {
  name: "Index",
  components: {
    SidenavList,
  },
  props: {
    customClass: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      logo,
      smLogo
    };
  },
  computed: {
    ...mapState(["isRTL", "isPinned"]),
  },
};
</script>
<style>
.logo-new {
  margin-left: 1.5rem;
  height: 40px;
  width: 180px;
  margin-top: 1.5rem;
}

.logo-new-sm {
  margin-left: 1.25rem;
  height: 62px;
  width: 62px;
  margin-top: 0.65rem;
}
</style>
