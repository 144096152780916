<template>
    <!-- <div class="py-4 container-fluid">
    </div> -->
    <div class="py-4 container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="pb-0 card-header">
                        <div class="row">
                            <div class="col-8"></div>
                            <div class="col-4">
                                <div class="input-group mb-4">
                                    <span class="input-group-text text-body"><i class="fas fa-search"
                                            aria-hidden="true"></i>
                                    </span><input v-model="query" type="text" class="form-control"
                                        placeholder="Type here...">
                                </div>
                            </div>
                        </div>
                        <div class="d-lg-flex">
                            <div>
                                <h5 class="mb-0">Assigned Policy Books</h5>
                            </div>
                        </div>
                    </div>
                    <div class="px-0 pb-0 card-body">
                        <div class="table-responsive">
                            <table id="policies-list" class="table table-flush table-hover">
                                <thead class="thead-light">
                                    <tr>
                                        <th class="text-center">Name</th>
                                        <th class="text-center">Description</th>
                                        <th class="text-center">Policies</th>
                                        <th class="text-center">Assigned On</th>
                                        <th class="text-center">Acknowledged On</th>
                                        <th class="text-center">Status</th>
                                        <th class="text-center" v-if="user.role === 'admin'">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="assigned in filteredPolicies" :key="assigned.id" class="py-3"
                                        @dblclick="push(assigned.id)">
                                        <td class="text-center">
                                            <h6 class="my-auto ms-3">{{ assigned.policy_book.name }}</h6>
                                        </td>
                                        <td class="text-sm text-center wrap-text">{{ assigned.policy_book.description
                                        }}</td>
                                        <td class="text-sm text-center">{{ assigned.policy_book.policies.length }}
                                        </td>
                                        <td class="text-sm text-center">{{ assigned.assigned_at }}</td>
                                        <td class="text-sm text-center">{{ assigned.acknowledged_at || 'N/A' }}</td>
                                        <td class="text-center">
                                            <span class="badge badge-sm"
                                                :class="{ 'badge-success': assigned.acknowledged, 'badge-secondary': !assigned.acknowledged }">{{
                                                    assigned.acknowledged ? 'Acknowledged' : 'Not Acknowledged'
                                                }}</span>
                                        </td>
                                        <td class="text-sm text-center" v-if="user.role === 'admin'">
                                            <a :id="'view_' + assigned.id" data-bs-toggle="tooltip"
                                                data-bs-original-title="Preview product">
                                                <i class="fas fa-eye text-secondary" @click="push(assigned.id)"></i>
                                            </a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "PolicyMaster",
    data() {
        const user = JSON.parse(localStorage.getItem('user'));
        return {
            policies: [],
            query: '',
            user
        }
    },
    computed: {
        filteredPolicies() {
            return this.policies.filter((ele) => {
                return ele.assigned_at.toLowerCase().includes(this.query.toLowerCase()) ||
                    ele.policy_book.name.toLowerCase().includes(this.query.toLowerCase()) ||
                    ele.policy_book.description.toLowerCase().includes(this.query.toLowerCase())
            });
        },
    },
    mounted() {
        this.$http.get('api/policies/assigned').then((res) => {
            this.policies = res.data
        })
    },
    methods: {
        push(id) {
            console.log('here')
            this.$router.push({ name: 'View Assigned Policy', params: { id: id } })
        },
    },
};
</script>

<style scoped>
.wrap-text {
    white-space: normal;
}
</style>