import store from "@/store";

export default function superadmin({ router, next }) {
    const user = JSON.parse(localStorage.getItem('user'))

    if (user.role !== 'super-admin') {
        return router.push({ name: "home" });
    }
    // next();
}
