<template>
    <div class="py-4 container-fluid vh-screen-82">
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="pb-0 card-header">
                        <div class="row">
                            <div class="col-8"></div>
                            <div class="col-4">
                                <div class="input-group mb-4">
                                    <span class="input-group-text text-body"><i class="fas fa-search"
                                            aria-hidden="true"></i>
                                    </span><input v-model="query" type="text" class="form-control"
                                        placeholder="Type here...">
                                </div>
                            </div>
                        </div>
                        <div class="d-lg-flex">
                            <div>
                                <h5 class="mb-0">All Standard Operational Procedures (SOP)</h5>
                            </div>
                            <div class="my-auto mt-4 ms-auto mt-lg-0">
                                <div class="my-auto ms-auto">
                                    <button v-if="user.role === 'admin'" class="mb-0 btn btn-success btn-md"
                                        @click="$router.push({ name: 'Add SOP' })">+&nbsp; New
                                        SOP</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="px-0 pb-0 card-body">
                        <div class="table-responsive">
                            <table id="sops-list" class="table table-flush table-hover">
                                <thead class="thead-light">
                                    <tr>
                                        <th class="text-center">Name</th>
                                        <th class="text-center">Category</th>
                                        <th class="text-center">Department</th>
                                        <th class="text-center" v-if="user.role === 'admin'">Status</th>
                                        <th class="text-center" v-if="user.role === 'admin'">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="sop in filteredSOPS" :key="sop.id" class="py-3" @dblclick="push(sop.id)">
                                        <td class="text-center">
                                            <h6 class="my-auto ms-3">{{ sop.name }}</h6>
                                        </td>
                                        <td class="text-sm text-center">{{ sop.category.name }}</td>
                                        <td class="text-sm text-center">{{ sop.department.name }}</td>
                                        <td class="text-center" v-if="user.role === 'admin'">
                                            <span class="badge badge-sm"
                                                :class="{ 'badge-danger': sop.status == 0, 'badge-success': sop.status == 1 }">{{
                                                    sop.status == 1 ? 'Active' : 'In-Active' }}</span>
                                        </td>
                                        <td class="text-sm text-center" v-if="user.role === 'admin'">
                                            <a :id="'view_' + sop.id" data-bs-toggle="tooltip"
                                                data-bs-original-title="Preview product">
                                                <i class="fas fa-eye text-secondary" @click="push(sop.id)"></i>
                                            </a>
                                            <a v-if="user.role === 'admin'" :id="'edit_' + sop.id" class="mx-3"
                                                data-bs-toggle="tooltip" data-bs-original-title="Edit product"
                                                @click="$router.push({ name: 'Edit SOP', params: { id: sop.id } })">
                                                <i class="fas fa-user-edit text-secondary"></i>
                                            </a>
                                            <a v-if="user.role === 'admin'" :id="'delete_' + sop.id"
                                                data-bs-toggle="tooltip" data-bs-original-title="Delete product"
                                                @click=deleteSOP(sop.id)>
                                                <i class="fas fa-trash text-danger"></i>
                                            </a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// import { DataTable } from "simple-datatables";
// import setTooltip from "@/assets/js/tooltip.js";

export default {
    name: "SOPMaster",
    data() {
        const user = JSON.parse(localStorage.getItem('user'));
        return { sops: [], sops2: [], query: '', user }
    },
    computed: {
        filteredSOPS() {
            return this.sops.filter((ele) => {
                return ele.name.toLowerCase().includes(this.query.toLowerCase()) ||
                    ele.department.name.toLowerCase().includes(this.query.toLowerCase()) ||
                    ele.category.name.toLowerCase().includes(this.query.toLowerCase())
            });
        }
    },
    mounted() {
        this.$http.get('api/sops').then((res) => {
            console.log(res.data, 'res')
            this.sops = res.data
        })
        // if (document.getElementById("sops-list")) {
        //     // eslint-disable-next-line no-unused-vars
        //     const dataTableSearch = new DataTable("#sops-list", {
        //         searchable: true,
        //         fixedHeight: false,
        //         perPage: 7
        //     });
        // }
        // setTooltip(this.$store.state.bootstrap);
    },
    methods: {
        push(id) {
            console.log('here')
            this.$router.push({ name: 'View SOP', params: { id: id } })
        },
        deleteSOP(id) {
            this.$alert.tableDelete({
                path: `/api/sops/${id}`
            }).then((res) => {
                if (res.isConfirmed) {
                    this.$http.get('api/sops').then((res) => {
                        this.sops = res.data
                    })
                }
            })
        }
    },
};
</script>