<template>
    <div v-if="loaded" class="container-fluid ps-0 w-100">
        <div class="row">
            <div class="col-3 d-lg-block d-md-block d-none">
                <div class="sideMenu">
                    <div class="details p-3">
                        <div class="policy-title-icon mx-auto">
                            <svg class="svg-inline--fa" viewBox="0 0 384 512">
                                <path fill="currentColor"
                                    d="M64 464c-8.8 0-16-7.2-16-16V64c0-8.8 7.2-16 16-16H224v80c0 17.7 14.3 32 32 32h80V448c0 8.8-7.2 16-16 16H64zM64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V154.5c0-17-6.7-33.3-18.7-45.3L274.7 18.7C262.7 6.7 246.5 0 229.5 0H64zm56 256c-13.3 0-24 10.7-24 24s10.7 24 24 24H264c13.3 0 24-10.7 24-24s-10.7-24-24-24H120zm0 96c-13.3 0-24 10.7-24 24s10.7 24 24 24H264c13.3 0 24-10.7 24-24s-10.7-24-24-24H120z">
                                </path>
                            </svg>
                        </div>
                        <div class="text-center policy-title">
                            <h4>{{ policyBook?.name }}</h4>
                        </div>
                    </div>
                    <div class="policy-progress text-center px-4">
                        <progress class="w-100" :value="getProgress" max="100"></progress> {{ `${getProgress}%` }}
                    </div>
                    <hr class="mt-3 mb-1 horizontal dark">
                    <h6 class="ms-4 mt-3">Policies</h6>
                    <div class="policy-container">
                        <a class="policy mb-1" :class="{ 'active': index === activePolicy }"
                            v-for="(policy, index) in policyBook.policies" @click="togglePolicy(index)">
                            <div class="policy-icon-container">
                                <div class="policy-wrapper">
                                    <svg class="svg-inline--fa" role="img" xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 512 512">
                                        <path fill="currentColor"
                                            d="M32 48c0 8.8 7.2 16 16 16H64V192H32c-8.8 0-16 7.2-16 16s7.2 16 16 16h96c8.8 0 16-7.2 16-16s-7.2-16-16-16H96V48c0-8.8-7.2-16-16-16H48c-8.8 0-16 7.2-16 16zM192 96c0 8.8 7.2 16 16 16H496c8.8 0 16-7.2 16-16s-7.2-16-16-16H208c-8.8 0-16 7.2-16 16zm0 160c0 8.8 7.2 16 16 16H496c8.8 0 16-7.2 16-16s-7.2-16-16-16H208c-8.8 0-16 7.2-16 16zm0 160c0 8.8 7.2 16 16 16H496c8.8 0 16-7.2 16-16s-7.2-16-16-16H208c-8.8 0-16 7.2-16 16zM58.7 323.9c11.1-11.1 29.2-10.5 39.6 1.3c9.2 10.5 8.9 26.2-.6 36.3L12.3 453.1c-4.3 4.7-5.5 11.5-3 17.3s8.3 9.6 14.7 9.6H128c8.8 0 16-7.2 16-16s-7.2-16-16-16H60.8l60.3-64.6c20.7-22.2 21.2-56.4 1.3-79.3c-22.5-25.7-62.1-27.1-86.3-2.9L20.7 316.7c-6.2 6.2-6.2 16.4 0 22.6s16.4 6.2 22.6 0l15.4-15.4z">
                                        </path>
                                    </svg>
                                    <p class="policy-text mb-0">{{ policy.name || 'New Policy' }}</p>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-lg-9 col-md-9 col-12">
                <navbar
                    class="position-sticky blur shadow-blur left-auto top-1 z-index-sticky px-0 mx-lg-0 mx-md-0 me-lg-4 mx-md-0 mx-4"
                    :text-white="isAbsolute ? 'text-white opacity-8' : ''" empty />
                <div id="card-container" class="p-5 px-4 ps-lg-0 ps-md-0 card-container hide-scroll">
                    <div class="card ms-1">
                        <div class="pb-0 mb-2 card-header">
                            <div class="text-center">
                                <h3 class="mb-0">{{ policyBook?.name }}</h3>
                                <hr class="mt-3 mb-1 horizontal dark">
                            </div>
                            <div class="text-left mt-4">
                                <h4 class="ps-4">{{ policyBook?.policies[activePolicy]?.name }}</h4>
                            </div>
                        </div>
                        <div class="px-0 pb-0 pt-0 card-body">
                            <div class="p-5 pb-0 pt-0">
                                <div class="row">
                                    <div class="col-12">
                                        <div class="tab-content" v-for="(policy, index) in policyBook.policies"
                                            v-show="activePolicy === index">
                                            <div class="row">
                                                <div class="col-12 px-4 py-3">
                                                    <TextEditor v-model="policy.content" mode="view" label="Content"
                                                        :quill-id="'quill_policy_' + index" no-label no-top-margin>
                                                    </TextEditor>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card-footer pt-0">
                            <hr>
                            <div class="align-self-end">
                                <button class="btn btn-success btn-lg me-3"
                                    v-if="activePolicy < this.policyBook.policies.length - 1"
                                    @click.prevent="next">Next</button>
                                <button class="btn btn-secondary btn-lg" @click.prevent="$alert.back"
                                    v-if="activePolicy === 0">Back</button>
                                <button class="btn btn-secondary btn-lg" @click.prevent="previous" v-else>Previous</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <template v-else>
        <SimpleLoader large full></SimpleLoader>
    </template>
</template>

<script>
import TextEditor from '@/components/TextEditor.vue';
import Navbar from "@/layout/Navbar.vue";
import SimpleLoader from "@/components/SimpleLoader.vue"

import { mapMutations, mapState } from "vuex";

export default {
    name: "AddPolicy",
    components: {
        TextEditor,
        Navbar,
        SimpleLoader
    },
    data() {
        return {
            policyBook: {
                policies: [],
            },
            activePolicy: 0,
            loaded: false
        }
    },
    mounted() {
        let id = this.$router.currentRoute.value.params.id
        this.$http.get('api/policies/' + id)
            .then((res) => {
                this.policyBook = res.data
                this.policyBook.general = this.policyBook.general === 0 ? false : true
                this.loaded = true
            })
    },
    created() {
        this.toggleEveryDisplay();
        this.toggleHideConfig();
    },
    beforeUnmount() {
        this.toggleEveryDisplay();
        this.toggleHideConfig();
    },
    computed: {
        ...mapState([
            "isNavFixed",
            "isAbsolute",
            "navbarFixed",
            "absolute",
        ]),
        getProgress() {
            return ((this.activePolicy + 1) / this.policyBook.policies.length) * 100
        }
    },
    methods: {
        ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),
        togglePolicy(index) {
            this.activePolicy = index
            this.scrollTop()
        },
        next() {
            if (this.activePolicy < this.policyBook.policies.length - 1) {
                this.activePolicy += 1
                this.scrollTop()
            }
        },
        previous() {
            if (this.activePolicy > 0) {
                this.activePolicy -= 1
                this.scrollTop()
            }
        },
        scrollTop() {
            var element = document.getElementById('card-container');
            if (element) {
                element.scrollTop = 0;
            }
        }
    }
}
</script>

<style scoped>
.sideMenu {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    width: 20rem;
    background: rgb(255, 255, 255);
    border-right: 1px solid rgb(241, 241, 241);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 10px;
    overflow: hidden;
    z-index: 51;
}

.navBar {
    display: flex;
    position: fixed;
    width: inherit;
    z-index: 50;
    background-color: rgb(255, 255, 255);
    border-bottom: 1px solid rgb(241, 241, 241);
    height: 4rem;
    -webkit-box-align: center;
    align-items: center;
}

.card {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 5px 10px;
}

.hover:hover {
    background-color: darkgray;
}

.policy {
    cursor: pointer;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    justify-content: space-between;
    padding: 0px 1.5rem;
    min-height: 2.75rem;
}

.policy:hover {
    background-color: rgb(241, 241, 241);
}

.policy:hover .policy-text {
    font-weight: bold;
    color: #67b108
}

.policy.active {
    background-color: rgb(241, 241, 241);
}

.policy.active .policy-text {
    font-weight: bold;
    color: #67b108
}

.policy-icon-container {
    display: flex;
    cursor: pointer;
    margin-right: 0.125rem;
    font-size: 1rem;
    line-height: 1.25rem;
}

.policy-wrapper {
    display: flex;
    -webkit-box-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    align-items: center;
    width: 100%;
}

.svg-inline--fa {
    height: 1.1em;
    overflow: visible;
    color: black
}

.policy-text {
    padding-left: 0.5rem;
    overflow-wrap: break-word;
    cursor: pointer;
    color: black;
    font-weight: normal;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    font-size: 1rem;
    line-height: 1.125rem;
}

.card-container {
    max-height: 89vh;
    overflow-y: auto;
}

.policy-title {
    font-weight: bold !important;
}

.policy-title-icon {
    /* margin: 2rem 0px 0.5rem; */
    margin-left: auto;
    border-radius: 50%;
    height: 3.5rem;
    width: 3.5rem;
    font-size: 1.424rem;
    line-height: 1.812rem;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    background-color: rgb(249, 249, 249);
    margin: 2rem 0px 0.5rem;
    border-radius: 50%;
    border: 1px solid rgb(241, 241, 241);
    height: 3.5rem;
    width: 3.5rem;
    cursor: pointer;
}

.policy-container {
    overflow-y: auto;
    max-height: 55vh;
}

progress::-webkit-progress-bar {
    /* background-color: yellow; */
    border-radius: 7px;
}

progress::-webkit-progress-value {
    background-color: #67b108;
    border-radius: 7px;
}

progress {
    border-radius: 7px;
    height: 8px;
}
</style>

