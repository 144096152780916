<template>
  <nav id="navbarBlur" class="shadow-none navbar navbar-main navbar-expand-lg border-radius-xl"
    :class="[isRTL ? 'top-1 position-sticky z-index-sticky' : '']" v-bind="$attrs" data-scroll="true">
    <div class="px-3 py-1 container-fluid">
      <breadcrumbs :current-directory="currentDirectory" :current-page="currentRouteName" :text-white="textWhite" />
      <template v-if="empty">
        <ul class="navbar-nav justify-content-end">

        </ul>
        <ul class="navbar-nav d-block">
          <li class="nav-item">
            <router-link :to="{ name: 'Dashboard' }"
              class="mb-0 btn btn-sm btn-round me-1 bg-gradient-success">Dashboard</router-link>
          </li>
        </ul>
      </template>
      <template v-else>
        <div class="sidenav-toggler sidenav-toggler-inner d-xl-block d-none" :class="isRTL ? 'me-3' : ''">
          <a href="#" class="p-0 nav-link text-body" @click.prevent="minNav">
            <div class="sidenav-toggler-inner">
              <i class="sidenav-toggler-line" :class="textWhite ? 'bg-white' : ''"></i>
              <i class="sidenav-toggler-line" :class="textWhite ? 'bg-white' : ''"></i>
              <i class="sidenav-toggler-line" :class="textWhite ? 'bg-white' : ''"></i>
            </div>
          </a>
        </div>
        <div id="navbar" class="mt-2 collapse navbar-collapse mt-sm-0 me-md-0 me-sm-4"
          :class="isRTL ? 'px-0' : 'me-sm-4'">
          <div class="pe-md-3 d-flex align-items-center" :class="isRTL ? 'me-md-auto' : 'ms-md-auto'">
            <!-- <div class="input-group">
            <span class="input-group-text text-body"><i class="fas fa-search" aria-hidden="true"></i></span>
            <input type="text" class="form-control" :placeholder="isRTL ? 'أكتب هنا...' : 'Type here...'" />
          </div> -->
          </div>
          <ul class="navbar-nav justify-content-end">
            <li class="nav-item d-flex align-items-center">
            </li>
            <li class="nav-item d-xl-none ps-3 d-flex align-items-center">
              <a id="iconNavbarSidenav" href="#" class="p-0 nav-link text-body" @click="toggleSidebar">
                <div class="sidenav-toggler-inner">
                  <i class="sidenav-toggler-line"></i>
                  <i class="sidenav-toggler-line"></i>
                  <i class="sidenav-toggler-line"></i>
                </div>
              </a>
            </li>
            <!-- <li class="px-3 nav-item d-flex align-items-center">
            <a class="p-0 nav-link" :class="textWhite ? textWhite : 'text-body'" @click="toggleConfigurator">
              <i class="cursor-pointer fa fa-cog fixed-plugin-button-nav"></i>
            </a>
          </li> -->
            <li class="nav-item dropdown d-flex align-items-center px-3" :class="isRTL ? 'ps-2' : 'pe-2'">
              <a id="dropdownMenuButton" href="#" class="p-0 nav-link" :class="[
                textWhite ? textWhite : 'text-body',
                showMenu ? 'show' : '',
              ]" data-bs-toggle="dropdown" aria-expanded="false" @click="showMenu = !showMenu">
                <span class="fs-5 me-2">{{ userData?.name }}</span> <i class="cursor-pointer fa fa-bell fs-5"></i>
              </a>
              <ul class="px-2 py-3 dropdown-menu dropdown-menu-end me-sm-n4" :class="showMenu ? 'show' : ''"
                aria-labelledby="dropdownMenuButton">
                <li class="mb-2">
                  <a class="dropdown-item border-radius-md" @click.prevent>
                    <div class="py-1 d-flex">
                      <div class="my-auto">
                        <img src="../assets/img/team-2.jpg" class="avatar avatar-sm me-3" alt="user image" />
                      </div>
                      <div class="d-flex flex-column justify-content-center">
                        <h6 class="mb-1 text-sm font-weight-normal">
                          {{ userData?.name }}
                        </h6>
                      </div>
                    </div>
                  </a>
                </li>
                <li>
                  <a class="dropdown-item border-radius-md" href="javascript:;" @click="logout">
                    <div class="py-1 d-flex">
                      <div class="my-auto avatar avatar-sm bg-gradient-danger me-3">
                        <i class="fa fa-user text-white"></i>
                      </div>
                      <div class="d-flex flex-column justify-content-center">
                        <h6 class="mb-1 text-sm font-weight-normal">
                          Sign Out
                        </h6>
                      </div>
                    </div>
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </template>
    </div>
  </nav>
</template>
<script>
import Breadcrumbs from "./Breadcrumbs.vue";
import { mapMutations, mapActions, mapState } from "vuex";

export default {
  name: "Navbar",
  components: {
    Breadcrumbs,
  },
  props: {
    minNav: {
      type: Function,
      default: () => { },
    },
    textWhite: {
      type: String,
      default: "",
    },
    empty: {
      type: Boolean,
      default: false
    }
  },
  data() {
    const userData = JSON.parse(localStorage.getItem('user'))
    return {
      showMenu: false,
      userData
    };
  },
  computed: {
    ...mapState(["isRTL"]),
    currentRouteName() {
      return this.$route.name;
    },
    currentDirectory() {
      let dir = this.$route.path.split("/")[1];
      return dir.charAt(0).toUpperCase() + dir.slice(1);
    },
  },
  created() {
    this.minNav;
  },
  methods: {
    ...mapMutations(["navbarMinimize", "toggleConfigurator"]),
    ...mapActions(["toggleSidebarColor"]),

    toggleSidebar() {
      this.toggleSidebarColor("bg-white");
      this.navbarMinimize();
    },
    logout() {
      // console.log('logout')
      const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      };
      this.$http.get('api/logout', config)
        .then((res) => {
          localStorage.removeItem('user');
          localStorage.removeItem('token');
          this.$router.push({ name: 'Login' })
        });
    }
  },
};
</script>
