<template>
    <!-- <div class="container top-0 position-sticky z-index-sticky">
        <div class="row">
            <div class="col-12">
                <navbar is-blur="blur blur-rounded my-3 py-2 start-0 end-0 mx-4 shadow" btn-background="bg-gradient-success"
                    :dark-mode="true" />
            </div>
        </div>
    </div> -->
    <main class="mt-0 main-content main-content-bg">
        <section>
            <div class="page-header min-vh-100">
                <div class="container">
                    <div class="row">
                        <div class="mx-auto col-xl-4 col-lg-5 col-md-6 d-flex flex-column">
                            <div class="mt-8 card card-plain">
                                <div class="pb-0 card-header text-start">
                                    <h3 class="font-weight-bolder text-success text-gradient">
                                        Welcome back
                                    </h3>
                                    <p class="mb-0">Enter your email and password to sign in</p>
                                </div>
                                <div class="card-body">
                                    <form role="form" class="text-start">
                                        <Input v-model="json['email']" label="Email" placeholder="Email"></Input>
                                        <Input v-model="json['password']" type="password" label="Password"
                                            placeholder="Password"></Input>
                                        <div class="text-center">
                                            <button class="btn btn-lg btn-block btn-success btn-gradient my-4 mb-2 w-100"
                                                @click.prevent="login">Sign
                                                in</button>
                                        </div>
                                    </form>
                                </div>
                                <div class="px-1 pt-0 text-center card-footer px-lg-2">
                                    <p class="mx-auto mb-4 text-sm">
                                        Don't have an account?
                                        <span class="text-success text-gradient font-weight-bold">Contact an Admin.</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="top-0 oblique position-absolute h-100 d-md-block d-none me-n8">
                                <div class="bg-cover oblique-image position-absolute fixed-top ms-auto h-100 z-index-0 ms-n6"
                                    :style="{
                                        backgroundImage:
                                            'url(' +
                                            require('@/assets/img/curved-images/curved9.jpg') +
                                            ')',
                                    }"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>
    <!-- <app-footer /> -->
</template>
  
<script>
// import AppFooter from "@/examples/PageLayout/Footer.vue";

import Input from "@/components/Input.vue";

import { mapMutations } from "vuex";

const body = document.getElementsByTagName("body")[0];

export default {
    name: "SignIn",
    components: {
        // AppFooter,
        Input
    },
    data() {
        return { json: {}, user: {} }
    },
    created() {
        this.toggleEveryDisplay();
        this.toggleHideConfig();
        body.classList.remove("bg-gray-100");
    },
    beforeUnmount() {
        this.toggleEveryDisplay();
        this.toggleHideConfig();
        body.classList.add("bg-gray-100");
    },
    methods: {
        ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),
        async login() {
            await this.$http.post("api/login", this.json)
                .then(async (res) => {
                    this.token = res.data.token
                    localStorage.setItem('token', this.token)
                    await this.getUser().then(() => {
                        const user = JSON.parse(localStorage.getItem('user'))
                        this.$alert.showSwal({
                            type: "success",
                            message: `Welcome back! ${user.name}`,
                            width: 350,
                        });
                        this.$router.push({ name: this.user['role'] !== 'super-admin' ? 'Dashboard' : 'Super Dashboard' })
                    })
                }).catch((res) => {
                    // console.log(res.response.data, 'failed')
                    if (res.response?.data) {
                        this.$alert.showSwal({
                            type: "error",
                            message: res.response.data.message,
                            width: 350,
                            timer: 5000
                        });
                    }
                })
        },

        async getUser() {
            const config = {
                headers: { Authorization: `Bearer ${this.token}` }
            };

            this.$http.defaults.headers.common = config['headers']

            await this.$http.get("api/user", config)
                .then(
                    (res) => {
                        this.user = res.data
                        localStorage.setItem('user', JSON.stringify(this.user))
                    }
                );
        }
    }
};
</script>
  